import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  deleteUser,
  loginUser,
  removeSignupData,
  resendOtp,
} from "../features/user/UserSlice";
import { useNavigate } from "react-router-dom";
import signin_banner from "../Images/signin_banner.jpg";
import talency_logo from "../Images/talency_logo.png";
import { BiSolidHide, BiSolidShow } from "react-icons/bi";

const initialState = {
  username: "",
  password: "",
  rememberMe: true,
};

const initialUserState = {
  accessToken: "",
  tokenType: "",
  role: "",
  userId: "",
  screen: "",
  active: false,
};

const DeleteUser = () => {
  const [values, setValues] = useState(initialState);
  const { isLoading } = useSelector((store) => store.user);
  const [isLoggedIn, setLoggedIn] = useState(false);

  const [isPasswordShow, setPasswordShow] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setValues({ ...values, [name]: value });
  };

  console.log(values);
  const onSubmit = (e) => {
    e.preventDefault();
    const { username, password } = values;
    if (!username || !password) {
      alert("Please fill out all fields");
      return;
    }
    dispatch(deleteUser({ emailOrMobile: username, password: password }));
    setLoggedIn(true);
  };

  // Regex code for

  return (
    <div className="login-container flex flex-col lg:flex-row h-screen">
      <div className="image-section relative w-full lg:w-1/2">
        <img
          src={signin_banner}
          alt="Login"
          className="object-cover h-full w-full"
        />
      </div>

      <div className="w-full lg:w-1/2">
        <div className="mt-20">
          <div
            to={"/"}
            className="flex w-full items-center justify-center  gap-2">
            <img
              src="https://clapurban.blr1.digitaloceanspaces.com/1713083984695_EMPLUX-removebg-preview (1).png"
              alt=""
              className="w-20"
            />
            <h1 className=" p-1  text-4xl font-bold uppercase text-primary-600  ">
              EMPLUX
            </h1>
          </div>
        </div>

        <div className="w-full p-6 mx-auto lg:max-w-xl mt-10">
          <div className="form-section">
            <form onSubmit={onSubmit}>
              {/* Login UI starts here */}
              <div className="mb-2 mt-5">
                {/* Email field */}
                <input
                  type="text"
                  name="username"
                  placeholder="Email Address"
                  onChange={handleChange}
                  className="block w-full px-4 py-2 mt-2 text-neutral-800 bg-stone-100 border rounded-md focus:border-neutral-800 focus:ring-indigo-600 focus:outline-none focus:ring focus:ring-opacity-40"
                />
              </div>
              {/* Password field */}
              <div className="mb-2 mt-5">
                <div className="relative ">
                  <div className="absolute right-3 top-1/2 transform -translate-y-1/2 h-max w-max">
                    {isPasswordShow ? (
                      <BiSolidHide
                        className="text-xl"
                        onClick={() => setPasswordShow(false)}
                      />
                    ) : (
                      <BiSolidShow
                        className="text-xl"
                        onClick={() => setPasswordShow(true)}
                      />
                    )}
                  </div>
                  <input
                    type={isPasswordShow ? "text" : "password"}
                    name="password"
                    placeholder="Password"
                    maxLength={30}
                    onChange={handleChange}
                    className="block w-full px-4 py-2 mt-2 text-neutral-800 bg-stone-100 border rounded-md focus:border-neutral-800 focus:ring-indigo-600 focus:outline-none focus:ring focus:ring-opacity-40"
                  />
                </div>
              </div>

              <div className="mt-6">
                <button
                  disabled={isLoading}
                  className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-neutral-800 rounded-md hover:bg-neutral-600 focus:outline-none focus:bg-purple-600">
                  {isLoading ? "Loading..." : "Delete"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteUser;
