import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { signUpUser } from "../features/user/UserSlice";
import { useNavigate } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import signin_banner from "../Images/signin_banner.jpg";
import talency_logo from "../Images/talency_logo.png";
import { BiSolidHide, BiSolidShow } from "react-icons/bi";
import { RxCrossCircled } from "react-icons/rx";

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  mobile: "",
  password: "",
  categoryType: "",
  confirmpassword: "",
};
const RegisterIndividual = () => {
  const [values, setValues] = useState(initialState);
  const [isDisable, setDisable] = useState(false);
  const [skillSet, setSkillSet] = useState([]);
  const [isPasswordShow, setPasswordShow] = useState(false);

  const { user, isLoading, status } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setValues({ ...values, [name]: value });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    const { firstName, lastName, mobile, email, password, categoryType } =
      values;
    if (
      !firstName ||
      !password ||
      !mobile ||
      !email ||
      !categoryType ||
      skillSet?.length < 1
    ) {
      alert("Please fill out all fields");
      return;
    }
    if (errors?.password || errors?.email) {
      alert("error!");
    } else
      dispatch(
        signUpUser({
          partnerFirstName: firstName,
          partnerLastName: lastName,
          partnerMobile: mobile,
          partnerEmail: email,
          password: password,
          categoryType: [categoryType],
          skillSet: skillSet,
          companyName: "",
          companyDomain: "",
          companyEmployer: false,
        })
      );
  };

  const toggleMember = () => {
    setTimeout(() => {
      navigate("/login");
    }, 10);
  };

  //Add skills
  const [isFocusedSkill, setFocusedSkill] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const handleSkillSetInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const handleSkillSetKeyDown = (event, next) => {
    if (
      event.key === "Enter" &&
      inputValue.trim() !== "" &&
      skillSet?.length < 4
    ) {
      setSkillSet([...skillSet, inputValue.trim()]);
      setInputValue("");
    } else if (skillSet?.length === 3) {
      document.getElementById(next)?.focus();
    }

    console.log(skillSet);
  };
  const removeSkill = (indexToRemove) => {
    setSkillSet(skillSet.filter((_, index) => index !== indexToRemove));
  };

  useEffect(() => {
    if (user) {
      setTimeout(() => {
        navigate("/");
      }, 10);
    }
  }, [user, navigate]);

  useEffect(() => {
    console.log(values);
    if (
      values.password === values.confirmpassword &&
      values.password &&
      values.confirmpassword
    ) {
      console.log("true");
      setDisable(true);
    } else setDisable(false);
  }, [values.confirmpassword, values.password]);

  //  Regex code for validation

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    companyname: "",
    password: "",
    categoryType: "",
  });

  const validateName = (firstName) => {
    const regex = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z])$/;
    return regex.test(firstName);
  };

  const validateLastName = (lastName) => {
    const regex = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z])$/;
    return regex.test(lastName);
  };

  const validateCompanyName = (companyname) => {
    const regex = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z])$/;
    return regex.test(companyname);
  };

  const validateEmail = (login) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(login);
  };
  const validatePassword = (Password) => {
    const regex = /^(?=.*[A-Z])(?=.*\d).{6,}$/;
    return regex.test(Password);
  };

  const handleInputFocusOut = (event) => {
    const { name, value } = event.target;
    if (name === "email" && !validateEmail(value)) {
      setErrors({ ...errors, email: "Please enter a valid email" });
    } else if (name === "firstName" && !validateName(value)) {
      setErrors({
        ...errors,
        firstName:
          "at least 2 characters & does not start or end with a special character",
      });
    } else if (name === "lastName" && !validateLastName(value)) {
      setErrors({
        ...errors,
        lastName:
          "at least 2 characters & does not start or end with a special character",
      });
    } else if (name === "companyname" && !validateCompanyName(value)) {
      setErrors({
        ...errors,
        companyname:
          "at least 2 characters & does not start or end with a special character",
      });
    } else if (name === "password" && !validatePassword(value)) {
      setErrors({
        ...errors,
        password:
          "password should contain [a-z] atleast one uppercase and atleast one number",
      });
    } else {
      // input is correct, remove error message for this field
      const newErrors = { ...errors };
      delete newErrors[name];
      setErrors(newErrors);
    }
  };

  const moveToNextInput = (event, nextInputId, prevInputId) => {
    switch (event.key) {
      case "Enter":
        event.preventDefault();
        document.getElementById(nextInputId)?.focus();
        break;
      case "ArrowUp":
        event.preventDefault();
        document.getElementById(prevInputId)?.focus();
        break;
      case "ArrowLeft":
        event.preventDefault();
        document.getElementById(prevInputId)?.focus();
        break;
      case "ArrowDown":
        event.preventDefault();
        document.getElementById(nextInputId)?.focus();
        break;
      case "ArrowRight":
        event.preventDefault();
        document.getElementById(nextInputId)?.focus();
        break;
    }
  };

  useEffect(() => {
    if (status === 201) {
      navigate(`/register/verify-otp/${values?.email}`);
    }
  }, [status]);

  return (
    <div className="login-container flex flex-col lg:flex-row h-screen">
      <div className="image-section relative w-full lg:w-1/2">
        <img
          src={signin_banner}
          alt="Login"
          className="object-cover h-full w-full"
        />
      </div>

      <div className="w-full lg:w-1/2">
        {/* <div className="mt-10">
          <img src={talency_logo} alt="Logo" className="mx-auto h-12" />
        </div> */}

        <div className="w-full p-6 mx-auto lg:max-w-xl mt-10">
          <div className="form-section">
            <form onSubmit={onSubmit}>
              {/* Sign-up UI starts here */}
              <div className="mb-2 mt-1 flex gap-2">
                {/* First Name*/}
                <div className="w-full">
                  <input
                    className="block w-full px-4 py-2 mt-2 text-neutral-800 bg-stone-100 border rounded-md focus:border-neutral-800 focus:ring-indigo-600 focus:outline-none focus:ring focus:ring-opacity-40"
                    type="text"
                    name="firstName"
                    id="firstName"
                    onKeyDown={(e) => moveToNextInput(e, "lastName")}
                    placeholder="First Name"
                    onChange={handleChange}
                    onBlur={handleInputFocusOut}
                  />
                </div>
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  placeholder="Last Name"
                  onKeyDown={(e) =>
                    moveToNextInput(e, "categoryType", "firstName")
                  }
                  onChange={handleChange}
                  className="block w-full px-4 py-2 mt-2 text-neutral-800 bg-stone-100 border rounded-md focus:border-neutral-800 focus:ring-indigo-600 focus:outline-none focus:ring focus:ring-opacity-40"
                />
              </div>
              <div className="mb-2 mt-1 flex gap-2">
                {/* Category*/}
                <div className="w-1/4">
                  <select
                    className="block w-full px-2 py-2 mt-2 text-neutral-800 bg-stone-100 border rounded-md focus:border-neutral-800 focus:ring-indigo-600 focus:outline-none focus:ring focus:ring-opacity-40 capitalize appearance-none"
                    type="text"
                    name="categoryType"
                    id="categoryType"
                    onKeyDown={(e) =>
                      moveToNextInput(e, "skillSet", "lastName")
                    }
                    value={values?.categoryType}
                    onChange={handleChange}
                    onBlur={handleInputFocusOut}>
                    <option value="" disabled selected>
                      Select Category
                    </option>
                    <option value="mechanical">mechanical</option>
                    <option value="electrical">electrical</option>
                    <option value="electronics">electronics</option>
                  </select>
                </div>
                {/* Skills */}
                <div
                  className={
                    "w-3/4 flex mt-2 bg-stone-100 border rounded-md overflow-hidden " +
                    (isFocusedSkill
                      ? " border-neutral-800 ring-indigo-600 ring ring-opacity-40 "
                      : " ")
                  }>
                  <div
                    className="flex items-center gap-1 pl-2 "
                    onClick={() => {
                      document.getElementById("skillSet").focus();
                    }}>
                    {skillSet?.map((elem, index) => {
                      return (
                        <div className=" bg-black text-white px-2 py-1 flex justify-between max-w-[6rem] overflow-hidden items-center rounded-md">
                          <span className="w-[3rem] overflow-hidden inline-block whitespace-nowrap overflow-ellipsis ">
                            {elem}
                          </span>
                          <div>
                            <RxCross2
                              onClick={() => removeSkill(index)}
                              className="cursor-pointer text-lg  "
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <input
                    type="text"
                    name="skillSet"
                    id="skillSet"
                    placeholder={
                      skillSet?.length < 3
                        ? "Skills eg.TV repair,laptop repair"
                        : ""
                    }
                    onKeyDown={(e) => handleSkillSetKeyDown(e, "mobile")}
                    onChange={handleSkillSetInputChange}
                    onFocus={() => setFocusedSkill(true)}
                    onBlur={() => setFocusedSkill(false)}
                    value={inputValue}
                    className="block w-full pr-4 pl-2 py-2  text-neutral-800  bg-stone-100  "
                  />
                </div>
              </div>

              <div className="mb-2 mt-5">
                {/* Email field */}
                <input
                  type="tel"
                  name="mobile"
                  id="mobile"
                  onKeyDown={(e) => moveToNextInput(e, "email", "skillSet")}
                  placeholder="Mobile Number"
                  onChange={handleChange}
                  className="block w-full px-4 py-2 mt-2 text-neutral-800 bg-stone-100 border rounded-md focus:border-neutral-800 focus:ring-indigo-600 focus:outline-none focus:ring focus:ring-opacity-40"
                />
              </div>
              <div className="mb-2 mt-5">
                {/* Email field */}
                <input
                  type="email"
                  name="email"
                  id="email"
                  onKeyDown={(e) => moveToNextInput(e, "password", "mobile")}
                  placeholder="Email Address"
                  onChange={handleChange}
                  className="block w-full px-4 py-2 mt-2 text-neutral-800 bg-stone-100 border rounded-md focus:border-neutral-800 focus:ring-indigo-600 focus:outline-none focus:ring focus:ring-opacity-40"
                  onBlur={handleInputFocusOut}
                  style={errors.email ? { borderColor: "red" } : null}
                />
                {errors.email && (
                  <div className="text-xs max-h-[8px]" style={{ color: "red" }}>
                    {errors.email}
                  </div>
                )}
              </div>
              {/* Password field */}
              <div className="mb-2 mt-5">
                <div className="relative ">
                  <div className="absolute right-3 top-1/2 transform -translate-y-1/2 h-max w-max">
                    {isPasswordShow ? (
                      <BiSolidHide
                        className="text-xl"
                        onClick={() => setPasswordShow(false)}
                      />
                    ) : (
                      <BiSolidShow
                        className="text-xl"
                        onClick={() => setPasswordShow(true)}
                      />
                    )}
                  </div>
                  <input
                    type={isPasswordShow ? "text" : "password"}
                    name="password"
                    id="password"
                    onKeyDown={(e) =>
                      moveToNextInput(e, "confirmpassword", "email")
                    }
                    placeholder="Password"
                    onChange={handleChange}
                    className="block w-full px-4 py-2 mt-2 text-neutral-800 bg-stone-100 border rounded-md focus:border-neutral-800 focus:ring-indigo-600 focus:outline-none focus:ring focus:ring-opacity-40"
                    onBlur={handleInputFocusOut}
                    style={errors.password ? { borderColor: "red" } : null}
                  />
                </div>
                {errors.password && (
                  <div className="text-xs max-h-[8px]" style={{ color: "red" }}>
                    {errors.password}
                  </div>
                )}
              </div>

              <div className="mb-2 mt-5">
                <input
                  type="password"
                  name="confirmpassword"
                  id="confirmpassword"
                  onKeyDown={(e) => moveToNextInput(e, "submit", "password")}
                  placeholder="Confirm Password"
                  onChange={handleChange}
                  className="block w-full px-4 py-2 mt-2 text-neutral-800 bg-stone-100 border rounded-md focus:border-neutral-800 focus:ring-indigo-600 focus:outline-none focus:ring focus:ring-opacity-40"
                />
              </div>

              <div className="mt-6">
                <button
                  disabled={isLoading || !isDisable}
                  id="submit"
                  type="submit"
                  className={
                    isLoading || !isDisable
                      ? " cursor-not-allowed w-full  px-4 py-2 tracking-wide text-white  bg-neutral-800 rounded-md "
                      : "w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-neutral-800 rounded-md hover:bg-neutral-600 focus:outline-none focus:bg-purple-600"
                  }>
                  {isLoading ? "Loading..." : "Sign Up"}
                </button>
              </div>
              <div className="flex items-center mt-3 justify-center">
                <span className="w-1/3 border-b border-black"></span>
                <span className="w-1/5 text-center">OR</span>
                <span className="w-1/3 border-b border-black"></span>
              </div>
              <div className="mt-3">
                <button
                  disabled={isLoading}
                  type="button"
                  onClick={() => navigate("/register")}
                  className={
                    isLoading
                      ? " cursor-not-allowed w-full  px-4 py-2 tracking-wide text-black border-2 border-black font-semibold  rounded-md "
                      : "w-full px-4 py-2 tracking-wide text-black border-2 border-black font-semibold transition-colors duration-200 transform rounded-md hover:bg-neutral-200 focus:outline-none focus:bg-purple-600"
                  }>
                  Sign Up as Company
                </button>
              </div>

              <p className="mt-6 text-xs font-light text-center text-gray-700">
                Already have an account?
                <button
                  type="button"
                  className="font-medium text-neutral-800 outline-white hover:underline ml-2"
                  onClick={toggleMember}>
                  <strong>Sign In</strong>
                </button>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterIndividual;
