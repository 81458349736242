import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfile } from "../../features/Profile/ProfileSlice";
import { Link, useNavigate } from "react-router-dom";
import PrifileSidebar from "../../components/ProfileSidebar";
import Carousel from "../../components/Carousel";

const Documents = () => {
  const items = [
    "https://pmmodiyojana.in/wp-content/uploads/2022/08/image-170-1024x683.png",
    "https://www.indiastack.global/wp-content/uploads/2022/06/1-Brief-Background-b.png",
  ];

  const dispatch = useDispatch();

  const { isLoading, userData } = useSelector((state) => state.profile);
  console.log(userData);
  useEffect(() => {
    dispatch(fetchProfile());
  }, [dispatch]);
  const navigate = useNavigate();

  return (
    <div className=" bg-slate-50">
      <div className=" flex justify-center w-full ">
        <div className="  w-[900px] h-fit my-10 overflow-hidden ">
          {/* Head */}
          <div className="flex flex-col w-full border-b mx-4 pb-5 border-gray-400">
            <span className="text-xl font-bold my-1">Account</span>
            <span className="text-sm  font-thin">
              {userData?.firstName} {userData?.maidenName} {userData.lastName}
            </span>
          </div>

          {/* Profile box */}
          <div className="flex h-fit w-full ">
            {/* Side options */}
            <PrifileSidebar />
            {/* main details */}
            <div className="flex justify-center items-center w-full h-full ">
              <div className="flex justify-center w-[97%]  h-full mt-2 border-gray-400 relative ">
                <div className="flex flex-col  py-6 px-10 w-full  text-gray-600 ">
                  {/* <div className="flex justify-center border-b-2 w-full mb-10">
                    <h1 className="font-bold text-md my-3 ">Profile Details</h1>
                  </div> */}

                  {/* Main Details */}
                  <div className="mx-8 gap-6 min-w-max text-md flex flex-col ">
                    <div className="font-semibold text-xl text-black">
                      AADHAR
                    </div>
                    <div>AADHAAR No. : 877 959 706</div>

                    <div className="w-full h-full relative">
                      <Carousel items={items} />
                    </div>

                    <div className=" text-black font-semibold pb-1 text-xl">
                      Driving Lisence
                    </div>
                    <div>Id No. : 877 959 706</div>
                    <div className="border rounded-md p-2">
                      <img src="" alt="" className="h-48 w-full" />
                    </div>
                  </div>
                  <div className="flex justify-center w-full mt-3">
                    <Link className="flex justify-center w-full  p-1 my-6 bg-[#000] rounded-sm">
                      <span className="font-normal text-lg uppercase text-white">
                        Request Update
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Documents;
