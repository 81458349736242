import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  addUserToLocalStorage,
  getUserFromLocalStorage,
  removeUserFromLocalStorage,
} from "../../utils/localStorage";
import customFetch from "../../utils/axios";
import { clearStoreThunk } from "./userThunk";
import { fetchOrgpartnerProfile } from "../Profile/ProfileSlice";
import { toast } from "sonner";

const initialState = {
  isLoading: false,
  status: null,
  signupData: null,
  user: getUserFromLocalStorage(),
};

// signup api calling

// console.log(getValue);
// signin/ login api calling
export const loginUser = createAsyncThunk(
  "user/loginUser",
  async (user, thunkAPI) => {
    try {
      const resp = await customFetch.post("api/auth/login", user);
      return resp.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast("Invalid credentials");
      } else if (error.response && error.response.status === 500) {
        toast.error("Invalid credentials");
      } else if (error.response && error.response.status === 10002) {
        toast.warning("Email already existing");
      } else if (error.response && error.response.status === 10003) {
        toast.warning("Company already existing");
      }
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);
//Resend OTP post
export const resendOtp = createAsyncThunk(
  "user/resendOtp",
  async ({ emailOrMobile, resend }, thunkAPI) => {
    try {
      const resp = await customFetch.post("api/auth/resend-otp", {
        emailOrMobile: emailOrMobile,
      });
      if (resend) {
        toast("Otp sent to mail!");
      }
      return resp.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // alert("Invalid credentials");
      } else if (error.response && error.response.status === 10001) {
        alert("Username already existing");
      } else if (error.response && error.response.status === 10002) {
        alert("Email already existing");
      } else if (error.response && error.response.status === 10003) {
        alert("Company already existing");
      }
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

export const signUpUser = createAsyncThunk(
  "user/signUpUser",
  async (user, thunkAPI) => {
    console.log(user);
    try {
      const resp = await customFetch.post("api/auth/register", user);
      console.log(resp);
      return resp.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Invalid credentials");
      } else if (error.response && error.response.status === 400) {
        alert("Email already existing");
      } else if (error.response && error.response.status === 10001) {
        alert("Username already existing");
      } else if (error.response && error.response.status === 10002) {
        alert("Email already existing");
      } else if (error.response && error.response.status === 10003) {
        alert("Company already existing");
      } else {
        alert(error.response.data.msg);
      }
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

export const addMember = createAsyncThunk(
  "user/addMember",
  async (user, thunkAPI) => {
    const { companyId } = getUserFromLocalStorage();
    try {
      const resp = await customFetch.post("api/auth/register", {
        ...user,
        companyId: companyId,
      });
      thunkAPI?.dispatch(fetchOrgpartnerProfile());
      return resp.data;
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.warning("Email or mobile already exis!");
      } else if (error.response && error.response.status === 400) {
        toast.error("Email already existing");
      } else if (error.response && error.response.status === 10001) {
        toast.error("Username already existing");
      } else if (error.response && error.response.status === 10002) {
        toast.error("Email already existing");
      } else if (error.response && error.response.status === 10003) {
        toast.error("Company already existing");
      } else {
        toast.error(error.response.data.msg);
      }
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

//post aadhar
export const postAadhar = createAsyncThunk(
  "user/postAadhar",
  async ({ partnerId, data }, thunkAPI) => {
    const accessToken = thunkAPI.getState()?.user?.signupData?.accessToken;
    const userId = thunkAPI.getState()?.user?.signupData?.userId;
    console.log(accessToken);
    console.log(data);

    try {
      const resp = await customFetch.post(
        `/api/profile/${userId}/upload-adhar-card`,
        data,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      console.log(resp);
      return resp.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Invalid credentials");
      } else {
        alert(error.response.data.msg);
      }
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

//post PAN
export const postPAN = createAsyncThunk(
  "user/postpan",
  async ({ data }, thunkAPI) => {
    const accessToken = thunkAPI.getState()?.user?.signupData?.accessToken;
    const userId = thunkAPI.getState()?.user?.signupData?.userId;
    try {
      const resp = await customFetch.post(
        `/api/profile/${userId}/upload-pan-card`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(resp);
      return resp.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Invalid credentials");
      } else {
        alert(error.response.data.msg);
      }
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);
//post GST
export const postGST = createAsyncThunk(
  "user/postgst",
  async ({ partnerId, data }, thunkAPI) => {
    const accessToken = thunkAPI.getState()?.user?.signupData?.accessToken;
    const userId = thunkAPI.getState()?.user?.signupData?.userId;
    try {
      const resp = await customFetch.post(
        `/api/profile/${userId}/upload-GST`,
        data,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      console.log(resp);
      return resp.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Invalid credentials");
      } else {
        alert(error.response.data.msg);
      }
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "user/forgotPassword",
  async (email, thunkAPI) => {
    try {
      const resp = await customFetch.put("api/auth/forgetPassword", email);
      console.log(resp);
      alert(resp.data);
      return resp.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Invalid Email or Mobile");
      } else {
        alert(error?.response?.data?.msg);
      }
    }
  }
);

export const VerifyOtp = createAsyncThunk(
  "user/verifyotp",
  async (otp, thunkAPI) => {
    console.log(otp);
    try {
      const resp = await customFetch.put("api/auth/verify-account", otp);
      return resp.data;
    } catch (error) {
      if (error.response) {
        alert("Invalid Otp");
      }
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

// signin/ login api calling
export const setPassword = createAsyncThunk(
  "user/setPassword",
  async (password, thunkAPI) => {
    const url = window?.location?.href;
    const urls = url?.split("=");
    try {
      const key = urls[1];
      const resp = await customFetch.put("api/auth/set-password", password, {
        headers: { key: key },
      });
      console.log(resp);
      return resp.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Invalid Credentials");
      } else {
        alert(error.response.data.msg);
      }
    }
  }
);

// Delete user
export const deleteUser = createAsyncThunk(
  "user/deleteUser",
  async (user, thunkAPI) => {
    try {
      const respLogin = await customFetch.post("api/auth/login", user);
      let id = respLogin?.data?.userId;
      if (!id) {
        throw new Error("Error user!");
      }
      const resp = await customFetch.delete(`/api/auth/delete/user/${id}`);
      return resp.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Invalid Credentials");
      } else {
        alert(error.response.data.msg);
      }
    }
  }
);

export const clearStore = createAsyncThunk("user/clearStore", clearStoreThunk);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logoutUser: (state, { payload }) => {
      // console.log(payload);
      state.user = null;
      state.signupData = null;
      removeUserFromLocalStorage();
      if (payload) {
        alert(payload); // Show logout alert
      }
    },
    removeStatus: (state) => {
      state.status = null;
    },
    setLoading: (state) => {
      state.isLoading = false;
    },
    removeSignupData: (state) => {
      state.signupData = null;
    },
    shiftLogin: (state) => {
      state.user = state.signupData;
      addUserToLocalStorage(state.signupData);
      state.signupData = null;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loginUser.fulfilled, (state, { payload }) => {
        const user = payload;
        state.isLoading = false;
        console.log("Login user");
        console.log(payload.role);
        if (
          payload?.role === "ROLE_COMPANY" ||
          payload?.role === "ROLE_ADMIN"
        ) {
          console.log(payload?.companyId);
          if (payload?.companyId > 0) {
            state.user = user;
            addUserToLocalStorage(user);
          } else {
            state.signupData = user;
          }
        } else {
          if (parseInt(payload?.screen) >= 2) {
            state.user = user;
            addUserToLocalStorage(user);
          } else {
            state.signupData = user;
          }
        }
      })
      .addCase(loginUser.rejected, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(postAadhar.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postAadhar.fulfilled, (state, { payload }) => {
        const screen = payload?.screen;
        state.signupData = { ...state.signupData, screen: screen };
        state.isLoading = false;
      })
      .addCase(postAadhar.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(postPAN.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postPAN.fulfilled, (state, { payload }) => {
        const screen = payload?.screen;
        state.signupData = { ...state.signupData, screen: screen };
        state.isLoading = false;
      })
      .addCase(postPAN.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(postGST.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postGST.fulfilled, (state, { payload }) => {
        const screen = payload?.screen;
        state.signupData = { ...state.signupData, screen: screen };
        state.isLoading = false;
      })
      .addCase(postGST.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(clearStore.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(signUpUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(signUpUser.fulfilled, (state, { payload }) => {
        state.status = 201;
        state.signupData = payload;
        state.isLoading = false;
      })
      .addCase(signUpUser.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(resendOtp.pending, (state) => {
        // state.isLoading = true;
      })
      .addCase(resendOtp.fulfilled, (state) => {
        state.status = 201;
        // state.isLoading = false;
      })
      .addCase(resendOtp.rejected, (state) => {
        // state.isLoading = false;
      })
      .addCase(forgotPassword.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(forgotPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(forgotPassword.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(setPassword.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(setPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(setPassword.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(VerifyOtp.fulfilled, (state) => {
        // edit
        state.status = 200;
        state.isLoading = false;
        state.signupData = { ...state.signupData, active: true };
        alert("OTP Verified Successfully!");
      })
      .addCase(VerifyOtp.pending, (state) => {
        // edit
        state.isLoading = true;
      })
      .addCase(VerifyOtp.rejected, (state) => {
        // edit
        state.isLoading = false;
      })
      .addCase(addMember.fulfilled, (state) => {
        state.isLoading = false;
        toast.success("Member added Successfully!");
      })
      .addCase(addMember.pending, (state) => {
        // edit
        state.isLoading = true;
      })
      .addCase(addMember.rejected, (state) => {
        // edit
        state.isLoading = false;
      })
      .addCase(deleteUser.fulfilled, (state) => {
        state.isLoading = false;
        alert("Member Deleted Successfully!");
      })
      .addCase(deleteUser.pending, (state) => {
        // edit
        state.isLoading = true;
      })
      .addCase(deleteUser.rejected, (state) => {
        // edit
        state.isLoading = false;
      });
  },
});

export const {
  toggleSidebar,
  logoutUser,
  removeStatus,
  setLoading,
  shiftLogin,
  removeSignupData,
} = userSlice.actions;

export default userSlice.reducer;
