import React, { useState } from "react";
import { BiRightArrow, BiLeftArrow } from "react-icons/bi";

const Carousel = ({ items }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === items.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? items.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="carousel flex items-center justify-center space-x-4">
      <button
        className="hover:scale-110 p-1  text-black  rounded-md"
        onClick={prevSlide}>
        <BiLeftArrow />
      </button>
      <div className="carousel-content overflow-hidden relative">
        {items.map((item, index) => (
          <div
            key={index}
            className={`slide transform  transition-transform ${
              index === currentIndex
                ? "translate-x-0 opacity-100 "
                : "translate-x-full hidden "
            }`}>
            <img src={item} alt="" className="w-80 h-48 object-fill border  " />
          </div>
        ))}
      </div>
      <button
        className="hover:scale-110 p-1  text-black rounded-md"
        onClick={nextSlide}>
        <BiRightArrow />
      </button>
    </div>
  );
};

export default Carousel;
