import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { editProfile, fetchProfile } from "../../features/Profile/Profil eSlice";
import { Link, useNavigate } from "react-router-dom";
import { SlUserFemale, SlUser } from "react-icons/sl";
import { MdModeEdit } from "react-icons/md";
import { GrEdit } from "react-icons/gr";
import { AiOutlineClose } from "react-icons/ai";

import SignUpSidebar from "../components/SignUpSidebar";

const ProfileEdit = () => {
  return (
    <div className="overflow-hidden">
      {/* Profile box */}
      <div className=" h-fit w-full  ">
        <SignUpSidebar />
        {/* main box */}
        <form className="flex justify-center items-center w-full m-auto">
          <div className=" bg-white w-2/4 mt-5">
            <input
              className="block w-full px-4 py-2 mt-2 text-neutral-800 bg-stone-100 border rounded-md focus:border-neutral-800 focus:ring-indigo-600 focus:outline-none focus:ring focus:ring-opacity-40"
              type="text"
              name="firstName"
              id="firstName"
              // onKeyDown={(e) => moveToNextInput(e, "lastName")}
              placeholder="Organization Name"
              // onChange={handleChange}
              // onBlur={handleInputFocusOut}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfileEdit;
