import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import customFetch from "../../utils/axios";
import { getUserFromLocalStorage } from "../../utils/localStorage";
const initialState = {
  companyTransactions: [],
  isLoadingWallet: false,
};

//get wallet transactions list
export const getCompanyWalletTransactions = createAsyncThunk(
  "wallet/getwallettransactions",
  async (thunkAPI) => {
    try {
      const user = getUserFromLocalStorage();
      const resp = await customFetch.get(
        `/api/wallet/transactions/company/${user?.companyId}`,
        {
          headers: {
            Authorization: `Bearer ${user?.accessToken}`,
          },
        }
      );

      return resp?.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.msg);
    }
  }
);

const WalletSlice = createSlice({
  name: "wallet",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getCompanyWalletTransactions.fulfilled, (state, { payload }) => {
        state.isLoadingWallet = false;
        state.companyTransactions = payload;
      })
      .addCase(getCompanyWalletTransactions.pending, (state) => {
        state.isLoadingWallet = true;
      })
      .addCase(getCompanyWalletTransactions.rejected, (state) => {
        state.isLoadingWallet = false;
      });
  },
});

export default WalletSlice.reducer;
