import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfile } from "../../features/Profile/ProfileSlice";
import PrifileSidebar from "../../components/ProfileSidebar";
import { getCompanyWalletTransactions } from "../../features/wallet/WalletSlice";
import WalletAmount from "../../components/WalletAmount";

const Wallet = () => {
  const dispatch = useDispatch();

  const { isLoading, userData } = useSelector((state) => state.profile);

  useEffect(() => {
    dispatch(fetchProfile());
    dispatch(getCompanyWalletTransactions());
  }, [dispatch]);
  return (
    <div className="  ">
      <div className=" flex justify-center w-full n">
        <div className="   h-fit my-20 overflow-hidden w-[65.5rem]">
          {/* Head */}
          <div className="flex flex-col w-full border-b mx-4 pb-5 border-gray-400">
            <span className="text-xl font-bold my-1">Account</span>
            <span className="text-sm  font-thin">
              {userData?.partnerFirstName + " " + userData?.partnerLastName}
            </span>
          </div>

          {/* Profile box */}
          <div className="flex h-fit w-full ">
            {/* Side options */}
            <PrifileSidebar />
            {/* main details */}
            <section className="w-4/6 mx-auto mt-8 h-full">
              <WalletAmount isAllPassbook={true} />
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wallet;
