import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { RxCross1, RxCross2 } from "react-icons/rx";
import { LuFilePlus, LuImagePlus } from "react-icons/lu";
import { useDispatch, useSelector } from "react-redux";
import { addMember, signUpUser } from "../../features/user/UserSlice";
import { FaPlus } from "react-icons/fa";
const HandelMember = ({ memberId, handleManaging }) => {
  const InitialState = {
    id: 0,
    partnerFirstName: "",
    partnerLastName: "",
    partnerMobile: "",
    partnerEmail: "",
    password: "",
    companyEmployer: true,
  };
  const [isSubmitedDocument, setSubmitedDocument] = useState(false);
  const [document, setDocument] = useState(null);
  const [fullName, setFullName] = useState("");
  const [partnerDetail, setPartnerDetails] = useState(InitialState);
  const dispatch = useDispatch();
  const { isLoading } = useSelector((store) => store.user);

  //Add category
  const [category, setCategory] = useState("");
  const handleCategoryChange = (e) => {
    setCategory(e?.target?.value);
  };
  //Add skills
  const [skillSet, setSkillSet] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const handleSkillSetInputChange = (event) => {
    if (skillSet?.length < 3) {
      setInputValue(event.target.value);
    }
  };
  const handleSkill = () => {
    if (inputValue.trim() !== "" && skillSet?.length < 3) {
      setSkillSet([...skillSet, inputValue.trim()]);
      setInputValue("");
    }
  };
  const handleSkillSetKeyDown = (event, next) => {
    if (event.key === "Enter") {
      handleSkill();
    }
    // else if (skillSet?.length === 3) {
    //   document.getElementById(next)?.focus();
    // }
  };
  const removeSkill = (indexToRemove) => {
    setSkillSet(skillSet.filter((_, index) => index !== indexToRemove));
  };
  //###########################3

  //Clear Details
  const handleClearDetails = () => {
    setPartnerDetails(InitialState);
    setFullName("");
    setInputValue("");
    setSkillSet([]);
    setCategory("");
  };

  const handleSumbmitDetails = async (e) => {
    e.preventDefault();
    const {
      partnerFirstName,
      partnerLastName,
      partnerMobile,
      partnerEmail,
      password,
    } = partnerDetail;
    console.log(partnerDetail);
    if (
      !partnerFirstName ||
      !password ||
      !partnerMobile ||
      !partnerEmail ||
      !category ||
      skillSet?.length < 1
    ) {
      alert("Please fill out all fields");
      return;
    } else {
      await dispatch(
        addMember({
          partnerFirstName: partnerFirstName,
          partnerLastName: partnerLastName,
          partnerMobile: partnerMobile,
          partnerEmail: partnerEmail,
          password: password,
          categoryType: [category],
          skillSet: skillSet,
          companyRegistration: false,
          companyEmployer: true,
        })
      );

      handleManaging();
    }
  };

  const handleDataChange = (e) => {
    const name = e?.target?.name;
    const value = e?.target?.value;
    if (name === "name") {
      setFullName(value);
      var nameParts = value.trim()?.split(" ");

      // Extract first name and last name
      var firstName = nameParts[0];
      var lastName = nameParts.slice(1).join(" ");
      console.log(firstName);

      setPartnerDetails({
        ...partnerDetail,
        partnerFirstName: firstName,
        partnerLastName: lastName,
      });
    } else if (name === "partnerMobile") {
      const initial =
        /^\d+$/.test(value?.slice(-1)[0]) && parseInt(value?.slice(-1)[0]) > 0
          ? value?.slice(-1)[0]
          : "";
      const val = value?.slice(0, -1) + initial;
      console.log(val);
      setPartnerDetails({ ...partnerDetail, [name]: val });
      console.log(partnerDetail);
    } else setPartnerDetails({ ...partnerDetail, [name]: value });
  };

  const handleAddSkill = () => {
    handleSkill(); // Save the current skill
    setInputValue(''); // Clear the input for the next skill
  };

    




  return (
    <div className="bg-[#0004] fixed w-full h-full top-0 left-0 z-50 flex items-center justify-center">
      <div className="w-[60vw] bg-white h-[30vw] rounded-lg relative py-[1vw] flex items-center flex-col gap-[1vw] max-[1000px]:h-[70vw] ">
        <span
          className="p-2 flex items-center justify-center rounded-full bg-white absolute -top-[5vh] right-0 cursor-pointer "
          onClick={
            isSubmitedDocument
              ? () => setSubmitedDocument(false)
              : handleManaging
          }>
          <RxCross1 className="text-[1vw] max-[1000px]:text-[1.5vw]" />
        </span>

        <h1 className="text-[1.7vw] font-semibold max-[1000px]:text-[2.5vw]">
          {memberId ? "Edit Member Details" : "Add Member"}
        </h1>
        {/* inputs */}

        <form
          className="w-11/12 m-auto grid grid-cols-2 gap-y-[3vw] gap-x-[1vw] h-full min-h-max max-[1000px]:grid-cols-1"
          onSubmit={handleSumbmitDetails}>
          <div className="relative w-full h-full  ">
            <input
              type="text"
              name="name"
              placeholder="Full Name"
              onChange={handleDataChange}
              value={fullName}
              required
              className="w-full h-full text-[1vw] max-[1000px]:text-[1.5vw] px-[1vw] py-[0.5vw] border rounded-md"
            />
            <span className="bg-white absolute -top-[0.8vw] px-2 left-2 text-[1vw] h-[0.9vw] ">
              Full Name
            </span>
          </div>
          <div className="relative w-full h-full  ">
            <select
              type="text"
              required
              onChange={handleCategoryChange}
              value={category}
              placeholder="Department"
              className="w-full h-full text-[1vw] max-[1000px]:text-[1.5vw] px-[1vw] py-[0.5vw] border rounded-md">
              <option selected>Select Department</option>
              <option value={"mechanical"}>Mechanical</option>
              <option value={"electrical"}>Electrical</option>
              <option value={"electronics"}>Electronics</option>
            </select>
            {/* <span className="bg-white absolute -top-[0.8vw] px-2 left-2 text-[1vw] h-[0.9vw]">
                Department
              </span> */}
          </div>
          <div className="relative w-full h-full  ">
            <input
              type="email"
              name="partnerEmail"
              required
              value={partnerDetail?.partnerEmail}
              onChange={handleDataChange}
              placeholder="Email"
              className="w-full h-full text-[1vw] max-[1000px]:text-[1.5vw] px-[1vw] py-[0.5vw] border rounded-md"
            />
            <span className="bg-white absolute -top-[0.8vw] px-2 left-2 text-[1vw] h-[0.9vw]">
              Email
            </span>
          </div>
          <div className="relative w-full h-full">
            <input
              type="tel"
              name="partnerMobile"
              required
              placeholder="Mobile No."
              maxLength={10}
              minLength={10}
              value={partnerDetail?.partnerMobile}
              onChange={handleDataChange}
              className="w-full h-full text-[1vw] max-[1000px]:text-[1.5vw] px-[1vw] py-[0.5vw] border rounded-md"
            />
            <span className="bg-white absolute -top-[0.8vw] px-2 left-2 text-[1vw] h-[0.9vw]">
              Mobile No.
            </span>
          </div>
          <div
            className="relative w-full h-full flex items-center  border rounded-md"
            onClick={() => {
              document?.getElementById("skillSet")?.focus();
            }}>
            <div className="flex items-center gap-1 pl-2 ">
              {skillSet?.map((elem, index) => {
                return (
                  <div className=" bg-black text-white px-2 py-1 flex justify-between max-w-[9rem] overflow-hidden items-center rounded-md">
                    <span className="w-[5rem] overflow-hidden inline-block whitespace-nowrap overflow-ellipsis ">
                      {elem}
                    </span>
                    <div>
                      <RxCross2
                        onClick={() => removeSkill(index)}
                        className="cursor-pointer text-lg  "
                      />
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="flex items-center w-full">
  <input
    type="text"
    id="skillSet"
    value={inputValue}
    placeholder={
      skillSet?.length < 3 ? "Skills eg.TV repair,laptop repair" : ""
    }
    onKeyDown={(e) => handleSkillSetKeyDown(e, "")}
    onBlur={handleSkill}
    onChange={handleSkillSetInputChange}
    className="w-full h-full text-[1vw] max-[1000px]:text-[1.5vw] px-[1vw] py-[0.5vw] rounded-md"
  />
  {skillSet?.length < 3 && (
        <button
        type="button"
          onClick={handleAddSkill}
          className="ml-2  text-black  px-3 py-1"
        >
          <FaPlus />
        </button>
      )}
            </div>

            <span className="bg-white absolute -top-[0.8vw] px-2 left-2 text-[1vw] h-[0.9vw]">
              Skills
            </span>
          </div>
          <div className="relative w-full h-full  ">
            <input
              type="text"
              name="password"
              placeholder="Password"
              value={partnerDetail?.password}
              onChange={handleDataChange}
              className="w-full h-full text-[1vw] max-[1000px]:text-[1.5vw] px-[1vw] py-[0.5vw] border rounded-md"
            />
            <span className="bg-white absolute -top-[0.8vw] px-2 left-2 text-[1vw] h-[0.9vw]">
              Password
            </span>
          </div>

          <button
            type="button"
            className="w-full h-full uppercase font-semibold text-[1.2vw] max-[1000px]:text-[1.8vw] text-primary-max border border-primary-max rounded-lg"
            onClick={handleClearDetails}>
            Clear
          </button>
          <button
            type="submit"
            className="w-full h-full uppercase font-semibold text-[1.2vw] max-[1000px]:text-[1.8vw] bg-primary-max text-white rounded-lg"
            disabled={isLoading}>
            {isLoading ? "Loading.." : "Proceed"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default HandelMember;
