import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { HiOutlineMail, HiOutlinePhone } from "react-icons/hi";
import { GetOnPlayStore } from "../utils/Constants";
import { clearStore } from "../features/user/UserSlice";

const PaartnerLanding = () => {
  const { user } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onLogoutClick = () => {
    dispatch(clearStore(""));
  };
  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user]);
  return (
    <div className="bg-gray-100 h-screen flex justify-center items-center">
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
        className="max-w-lg bg-white shadow-lg rounded-lg p-8 flex flex-col items-center">
        <h1 className="text-3xl font-bold mb-4">Welcome to Emplux</h1>
        <p className="text-gray-600 text-center mb-6">
          Please download our app for better experience.
        </p>
        <p className="text-gray-600 text-center mb-6">
          <img src={GetOnPlayStore} alt="" />
        </p>
        <div className="flex space-x-4">
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className="bg-blue-500 text-white px-4 py-2 rounded-lg flex items-center"
            href="mailto:info@example.com">
            <HiOutlineMail className="mr-2" />
            Email Us
          </motion.button>
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className="bg-green-500 text-white px-4 py-2 rounded-lg flex items-center"
            href="tel:+1234567890">
            <HiOutlinePhone className="mr-2" />
            Call Us
          </motion.button>
        </div>
        <div className="flex space-x-4">
          <motion.buttons
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className="bg-red-500 text-white px-4  mt-4 py-2 rounded-lg flex items-center"
            onClick={onLogoutClick}>
            Logout
          </motion.buttons>
        </div>
      </motion.div>
    </div>
  );
};

export default PaartnerLanding;
