import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUserFromLocalStorage } from "../../utils/localStorage";
import customFetch from "../../utils/axios";
import { toast } from "sonner";

const initialState = {
  isBookingLoading: false,
  FutureBookings: [],
  CompletedBookings: [],
  CanceledBookings: [],
  orgPartnerBookings: [],
  BookingDetail: {},
};

//Get All bookings
export const getAllBookings = createAsyncThunk(
  "booking/getallbooking",
  async (partnerId, thunkAPI) => {
    const { userId, accessToken } = getUserFromLocalStorage();
    try {
      const resp = await customFetch.get(
        `api/bookings/${partnerId || userId}/confirm/bookings/user`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log(resp);
      return resp?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error?.message);
    }
  }
);

export const getCompanyBookings = createAsyncThunk(
  "booking/getCompanyBookings",
  async (thunkAPI) => {
    const { companyId, accessToken } = getUserFromLocalStorage();
    try {
      const resp = await customFetch.get(`api/bookings/company/${companyId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log(resp);
      return resp?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error?.message);
    }
  }
);

export const onGoingOTP = createAsyncThunk(
  "booking/onGoingOTP",
  async ({ bookingId, otp }, thunkAPI) => {
    const { accessToken } = getUserFromLocalStorage();

    try {
      const resp = await customFetch.get(
        `api/bookings/${bookingId}/ongoing/${otp}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      toast.success("OTP verified successfully");
      thunkAPI.dispatch(getBookingDetail(bookingId));

      return resp?.data;
    } catch (error) {
      toast.error("Invalid OTP");
      return thunkAPI?.rejectWithValue(error?.message);
    }
  }
);

export const isCompleted = createAsyncThunk(
  "booking/isCompleted",
  async ({ bookingId, partnerId }, thunkAPI) => {
    console.log("bookingId", bookingId);
    console.log("partnerId", partnerId);
    const { accessToken } = getUserFromLocalStorage();

    try {
      const resp = await customFetch.get(
        `api/bookings/${bookingId}/completed/${partnerId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      toast.success("Booking completed successfully");
      thunkAPI.dispatch(getBookingDetail(bookingId));

      return resp?.data;
    } catch (error) {
      toast.error("Failed to complete booking");
      return thunkAPI?.rejectWithValue(error?.message);
    }
  }
);

//Get Booking Detail
export const getBookingDetail = createAsyncThunk(
  "booking/getbookingdetail",
  async (bookingId, thunkAPI) => {
    const { userId, accessToken } = getUserFromLocalStorage();
    try {
      const resp = await customFetch.get(`api/bookings/${bookingId}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      // console.log(resp);
      return resp?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error?.message);
    }
  }
);

export const deletePartner = createAsyncThunk(
  "booking/deletePartner",
  async ({ bookingId, companyId }, thunkAPI) => {
    const { accessToken } = getUserFromLocalStorage();
    try {
      const resp = await customFetch.post(
        `api/Assign/company/re-assign/cancel-partner?bookingId=${bookingId}&companyId=${companyId}`,
        {}, // Keep the URL with query parameters
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      thunkAPI.dispatch(getBookingDetail(bookingId));
      console.log("Delete Response: ", resp); // Log the response for debugging
      return resp?.data; // Return the response data, if any
    } catch (error) {
      console.log("Delete Error: ", error); // Log error for debugging
      return thunkAPI?.rejectWithValue(error?.message); // Handle error with reject
    }
  }
);

export const deleteBooking = createAsyncThunk(
  "booking/deleteBooking",
  async ({ bookingId, companyId }, thunkAPI) => {
    const { accessToken } = getUserFromLocalStorage();
    try {
      const resp = await customFetch.delete(
        `api/Assign/company/cancel?bookingId=${bookingId}&companyId=${companyId}`, // Keep the URL with query parameters
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      // console.log(resp);
      console.log("Delete Response: ", resp); // Log the response for debugging
      return resp?.data; // Return the response data, if any
    } catch (error) {
      console.log("Delete Error: ", error); // Log error for debugging
      return thunkAPI?.rejectWithValue(error?.message); // Handle error with reject
    }
  }
);

//Assign partner
export const assignPartner = createAsyncThunk(
  "booking/assignPartner",
  async ({ bookingId, partnerId }, thunkAPI) => {
    const { companyId, accessToken } = getUserFromLocalStorage();
    try {
      const resp = await customFetch.post(
        "/api/Assign/company-partner",
        { bookingId: bookingId, companyId: companyId, partnerId: partnerId },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      thunkAPI.dispatch(getBookingDetail(bookingId));
      return resp?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error?.message);
    }
  }
);

const BookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllBookings.pending, (state) => {
        state.isBookingLoading = true;
      })
      .addCase(getAllBookings.fulfilled, (state, { payload }) => {
        const data = payload;
        state.orgPartnerBookings = data;
        state.isBookingLoading = false;
        state.FutureBookings = data?.filter(
          (elem) =>
            elem?.bookingConfirms?.slice(0, 1)[0]?.status?.toLowerCase() ===
              "future" ||
            elem?.bookingConfirms?.slice(0, 1)[0]?.status?.toLowerCase() ===
              "ongoing"
        );
        state.CompletedBookings = data?.filter(
          (elem) =>
            elem?.bookingConfirms?.slice(0, 1)[0]?.status?.toLowerCase() ===
            "completed"
        );
        state.CanceledBookings = data?.filter(
          (elem) =>
            elem?.bookingConfirms?.slice(0, 1)[0]?.status?.toLowerCase() ===
            "canceled"
        );
      })
      .addCase(getAllBookings.rejected, (state) => {
        state.isBookingLoading = false;
      })
      .addCase(getCompanyBookings.pending, (state) => {
        state.isBookingLoading = true;
      })
      .addCase(getCompanyBookings.fulfilled, (state, { payload }) => {
        const data = payload;
        state.orgPartnerBookings = data;
        state.isBookingLoading = false;
        state.FutureBookings = data?.filter(
          (elem) =>
            elem?.bookingConfirms?.slice(0, 1)[0]?.status?.toLowerCase() ===
              "future" ||
            elem?.bookingConfirms?.slice(0, 1)[0]?.status?.toLowerCase() ===
              "ongoing"
        );
        state.CompletedBookings = data?.filter(
          (elem) =>
            elem?.bookingConfirms?.slice(0, 1)[0]?.status?.toLowerCase() ===
            "completed"
        );
        state.CanceledBookings = data?.filter(
          (elem) =>
            elem?.bookingConfirms?.slice(0, 1)[0]?.status?.toLowerCase() ===
            "canceled"
        );
      })
      .addCase(getCompanyBookings.rejected, (state) => {
        state.isBookingLoading = false;
      })
      .addCase(getBookingDetail.pending, (state) => {
        state.isBookingLoading = true;
      })
      .addCase(getBookingDetail.fulfilled, (state, { payload }) => {
        const data = payload;
        state.isBookingLoading = false;
        state.BookingDetail = data;
      })
      .addCase(getBookingDetail.rejected, (state) => {
        state.isBookingLoading = false;
      })
      .addCase(assignPartner.pending, (state) => {
        state.isBookingLoading = true;
      })
      .addCase(assignPartner.fulfilled, (state) => {
        state.isBookingLoading = false;
      })
      .addCase(assignPartner.rejected, (state) => {
        state.isBookingLoading = false;
      });
  },
});

export default BookingSlice.reducer;
