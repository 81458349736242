import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import React from "react";
import { getUserFromLocalStorage } from "../../utils/localStorage";
import customFetch, { AdminCustomFetch } from "../../utils/axios";
const initialState = {
  uploadUrl: "",
  isUploadLoading: false,
};
export const uploadFile = createAsyncThunk(
  "file/uploadfile",
  async (file, thunkAPI) => {
    const user = getUserFromLocalStorage();
    const signupData = thunkAPI.getState()?.user?.signupData;
    console.log(signupData);
    let accessToken = user?.accessToken || signupData?.accessToken;
    if (!accessToken) {
      accessToken =
        "eyJhbGciOiJIUzM4NCJ9.eyJzdWIiOiJheXVzaG5peWFtQGdtYWlsLmNvbSIsImlhdCI6MTcxMDA1NDQwOCwiZXhwIjoxNzEwNjU5MjA4fQ.5lGal66f6UCpGKZ5OLPI_gHl9i4TP9YnkoOF9RMdvyeXH64FojJ4qn0vyeURAqi3";
    }
    // console.log(accessToken);
    try {
      const resp = await customFetch.post("api/file/upload", file, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return resp?.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.msg);
    }
  }
);
const FilesSlice = createSlice({
  name: "file",
  initialState,
  reducers: {
    setUploadLoading: (state, { payload }) => {
      state.isUploadLoading = payload;
    },
    clearFile: (state) => {
      state.uploadUrl = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadFile.fulfilled, (state, { payload }) => {
        state.isUploadLoading = false;
        state.uploadUrl = payload.image_url;
      })
      .addCase(uploadFile.pending, (state) => {
        state.isUploadLoading = true;
      })
      .addCase(uploadFile.rejected, (state) => {
        alert("error while uploading!");
        state.isUploadLoading = false;
      });
  },
});

export default FilesSlice.reducer;
export const { setUploadLoading, clearFile } = FilesSlice.actions;
