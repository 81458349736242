import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  VerifyOtp,
  removeStatus,
  resendOtp,
  signUpUser,
} from "../features/user/UserSlice";
import { useNavigate, useParams } from "react-router-dom";
import signin_banner from "../Images/signin_banner.jpg";
import talency_logo from "../Images/talency_logo.png";
import { IoIosRefresh } from "react-icons/io";

const initialState = {
  otp1: "",
  otp2: "",
  otp3: "",
  otp4: "",
};

const GetOtp = () => {
  const [values, setValues] = useState(initialState);
  const { partnerEmail } = useParams();

  const initialTime = 30; // 2 minutes in seconds
  const [seconds, setSeconds] = useState(initialTime);

  const { status, isLoading, signupData } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value?.replace(/[^0-9]/g, "");

    setValues({ ...values, [name]: value });
  };

  // Format time for time
  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const remainingSeconds = timeInSeconds % 60;

    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");

    return `${formattedMinutes}:${formattedSeconds}`;
  };
  const onSubmit = (e) => {
    e.preventDefault();
    const { otp1, otp2, otp3, otp4 } = values;

    if (!(otp1 && otp2 && otp3 && otp4)) {
      alert("Please fill out all fields");
      return;
    }
    const otp = otp1 + "" + otp2 + "" + otp3 + "" + otp4;
    dispatch(VerifyOtp({ emailOrMobile: partnerEmail, otp: otp }));
  };

  const inputfocus = (elmnt) => {
    if (
      elmnt.key === "Delete" ||
      elmnt.key === "Backspace" ||
      elmnt.key === "ArrowLeft"
    ) {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
    } else {
      const next = elmnt.target.tabIndex;
      if (next < 5) {
        elmnt.target.form.elements[next].focus();
      }
    }
  };

  const handleFocus = (e) => {
    const inputElement = document.getElementById(e.target.id);
    if (inputElement) {
      inputElement.select();
    }
  };
  console.log(seconds);

  //Resend OTP
  const handleResendOtp = async () => {
    await dispatch(resendOtp({ emailOrMobile: partnerEmail, resend: true }));
    setSeconds(initialTime);
    dispatch(removeStatus());
  };

  // useEffect(() => {
  //   if (status != 201) {
  //     navigate("/register");
  //   }
  // }, []);

  useEffect(() => {
    if (status == 200) {
      navigate(`/register/documents/aadhar/${partnerEmail}`);
    } else {
      dispatch(removeStatus());
      if (signupData?.active) {
        if (signupData?.screen === "2") {
          navigate(`/register/documents/pan/${partnerEmail}`);
        } else if (signupData?.screen === "3") {
          navigate(`/register/documents/gst/${partnerEmail}`);
        } else {
          navigate(`/register/documents/aadhar/${partnerEmail}`);
        }
      }
    }
  }, [status]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [seconds]);
  return (
    <div className="login-container flex flex-col lg:flex-row h-screen">
      <div className="image-section relative w-full lg:w-1/2">
        <img
          src={signin_banner}
          alt="Login"
          className="object-cover h-full w-full"
        />
      </div>

      <div className="w-full lg:w-1/2">
        <div className="mt-20">
          <div
            to={"/"}
            className="flex w-full items-center justify-center  gap-2">
            <img
              src="https://clapurban.blr1.digitaloceanspaces.com/1713083984695_EMPLUX-removebg-preview (1).png"
              alt=""
              className="w-20"
            />
            <h1 className=" p-1  text-4xl font-bold uppercase text-primary-600  ">
              EMPLUX
            </h1>
          </div>
        </div>

        <div className="w-full p-6 mx-auto lg:max-w-xl mt-10">
          <div className=" form-section ">
            <form onSubmit={onSubmit}>
              <div className="flex items-center gap-9 mb-2 mt-5 w-5/6 m-auto justify-center  ">
                {/* Email field */}
                {/* <input
                  type="email"
                  name="digit1"
                  placeholder="Email Address"
                  onChange={handleChange}
                  className="block w-full px-4 py-2 mt-2 text-neutral-800 bg-stone-100 border rounded-md focus:border-neutral-800 focus:ring-indigo-600 focus:outline-none focus:ring focus:ring-opacity-40"
                /> */}
                <input
                  type="text"
                  id="otp1"
                  onFocus={handleFocus}
                  className="h-24 w-20 rounded-lg  text-black border border-gray-300 text-3xl font-extrabold text-center"
                  placeholder="_"
                  name="otp1"
                  maxLength={1}
                  value={values.otp1}
                  onChange={handleChange}
                  onKeyUp={inputfocus}
                  style={{
                    "-webkit-appearance": "none",
                    "-moz-appearance": "textfield",
                  }}
                  tabIndex="1"
                />

                <input
                  type="text"
                  id="otp2"
                  onFocus={handleFocus}
                  className="h-24 w-20 rounded-lg  text-black border border-gray-300 text-3xl font-extrabold text-center"
                  placeholder="_"
                  name="otp2"
                  maxLength={1}
                  value={values.otp2}
                  onChange={handleChange}
                  onKeyUp={inputfocus}
                  tabIndex="2"
                />

                <input
                  type="text"
                  id="otp3"
                  onFocus={handleFocus}
                  name="otp3"
                  className="h-24 w-20 rounded-lg  text-black border border-gray-300 text-3xl font-extrabold text-center"
                  placeholder="_"
                  maxLength={1}
                  value={values.otp3}
                  onChange={handleChange}
                  onKeyUp={inputfocus}
                  tabIndex="3"
                />

                <input
                  type="text"
                  id="otp4"
                  onFocus={handleFocus}
                  name="otp4"
                  className="h-24 w-20 rounded-lg  text-black border border-gray-300 text-3xl font-extrabold text-center"
                  placeholder="_"
                  maxLength={1}
                  value={values.otp4}
                  onChange={handleChange}
                  onKeyUp={inputfocus}
                  tabIndex="4"
                />
              </div>

              <div className="mt-8 w-full flex justify-center">
                <button
                  disabled={isLoading}
                  type="submit"
                  className="w-5/6  py-2 tracking-wide text-white transition-colors duration-200 transform bg-black rounded-md hover:bg-neutral-600 focus:outline-none focus:bg-purple-600">
                  {isLoading ? "Loading..." : "Verify OTP "}
                </button>
              </div>

              <div className="mt-8 text-xs font-light m-auto  text-gray-700 flex items-center justify-center ">
                Resend OTP
                <span className="px-4 font-bold cursor-pointer">
                  {seconds > 0 ? (
                    formatTime(seconds)
                  ) : (
                    <span
                      className="flex gap-1 items-center text-[#0000EE] underline"
                      onClick={handleResendOtp}>
                      {"Resend"}
                      <IoIosRefresh />
                    </span>
                  )}
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetOtp;
