import React from "react";

const FormRow = ({
  title,
  name,
  hint,
  isRequired,
  handleChange,
  length,
  value,
  className,
}) => {
  return (
    <div className={"relative w-full border rounded-md p-1 mb-4 " + className}>
      <span className="absolute -top-3 capitalize text-sm text-gray-600 px-1 left-5 bg-white">
        {title}
      </span>
      <input
        type="text"
        name={name}
        className="h-full w-full px-2 py-3"
        placeholder={hint}
        value={value}
        onChange={handleChange}
        maxLength={length}
        minLength={length}
        required={isRequired}
      />
    </div>
  );
};

export default FormRow;
