import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import React from "react";
import { getUserFromLocalStorage } from "../../utils/localStorage";
import customFetch, { AdminCustomFetch } from "../../utils/axios";
import { removeSignupData, shiftLogin } from "../user/UserSlice";
import { toast } from "sonner";
const initialState = {
  companyData: {},
  isCompanyLoading: false,
};
export const addCompany = createAsyncThunk(
  "company/addCompany",
  async (data, thunkAPI) => {
    const accessToken = thunkAPI.getState()?.user?.signupData?.accessToken;
    console.log(data);

    try {
      const resp = await customFetch.post("/api/company", data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      // thunkAPI?.dispatch(shiftLogin());
      toast.success("Details added successfully");
      thunkAPI?.dispatch(removeSignupData());
      return resp?.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.msg);
    }
  }
);

export const fetchCompany = createAsyncThunk(
  "company/fetchCompany",
  async (thunkAPI) => {
    const user = getUserFromLocalStorage();

    let accessToken = user?.accessToken;
    try {
      const resp = await customFetch.get(`/api/company/${user?.companyId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log(resp);
      return resp?.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.msg);
    }
  }
);
const CompanySlice = createSlice({
  name: "company",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addCompany.fulfilled, (state, { payload }) => {
        state.isCompanyLoading = false;
        state.companyData = payload.image_url;
      })
      .addCase(addCompany.pending, (state) => {
        state.isCompanyLoading = true;
      })
      .addCase(addCompany.rejected, (state) => {
        alert("error while adding!");
        state.isCompanyLoading = false;
      })
      .addCase(fetchCompany.fulfilled, (state, { payload }) => {
        state.isCompanyLoading = false;
        state.companyData = payload;
      })
      .addCase(fetchCompany.pending, (state) => {
        state.isCompanyLoading = true;
      })
      .addCase(fetchCompany.rejected, (state) => {
        alert("error while fetcing!");
        state.isCompanyLoading = false;
      });
  },
});

export default CompanySlice.reducer;
