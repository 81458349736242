import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import customFetch from "../../utils/axios";
import { getUserFromLocalStorage } from "../../utils/localStorage";

const initialState = {
  isLoadingProfile: false,
  userData: {},
  orgPartners: [],
};

export const fetchProfile = createAsyncThunk(
  "userprofile/fetchUserProfile",
  async (_, thunkAPI) => {
    let url = getUserFromLocalStorage();
    let user_id = url?.userId;
    let token = url?.accessToken;

    try {
      const response = await customFetch.get(`api/profile/${user_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response);
      return response?.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // thunkAPI.dispatch(logoutUser());
        return thunkAPI.rejectWithValue("Unauthorized! Logging out... ");
      }
      return thunkAPI.rejectWithValue(
        error.response ? error.response.data : "An error occurred."
      );
    }
  }
);


//Get partners inside company
export const fetchOrgpartnerProfile = createAsyncThunk(
  "userprofile/fetchOrgpartnerProfile",
  async (_, thunkAPI) => {
    let url = getUserFromLocalStorage();
    let companyId = url?.companyId;
    let token = url?.accessToken;
    // console.log(url);
    // console.log(companyId);
    try {
      const response = await customFetch.get(
        `/api/profile/companyId/company/profile`,
        {
          params: { companyId: companyId },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data.content);
      return response?.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // thunkAPI.dispatch(logoutUser());
        return thunkAPI.rejectWithValue("Unauthorized! Logging out... ");
      }
      return thunkAPI.rejectWithValue(
        error.response ? error.response.data : "An error occurred."
      );
    }
  }
);




const profileSlice = createSlice({
  name: "profile",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfile.pending, (state) => {
        state.isLoadingProfile = true;
      })
      .addCase(fetchProfile.fulfilled, (state, { payload }) => {
        const data = payload;
        console.log(data);
        state.isLoadingProfile = false;
        state.userData = data;
      })
      .addCase(fetchProfile.rejected, (state) => {
        state.isLoadingProfile = false;
      })
      .addCase(fetchOrgpartnerProfile.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchOrgpartnerProfile.fulfilled, (state, action) => {
        state.orgPartners = action.payload; 
        state.status = "succeeded";
      })
      .addCase(fetchOrgpartnerProfile.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export default profileSlice.reducer;
