import React from "react";
import { BiSolidMap, BiBlock } from "react-icons/bi";
import { AiFillStar } from "react-icons/ai";
import { FaLocationDot } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { Encode } from "../../utils/utilFunctions";

const BookingCards = ({ bookings, handleClick }) => {
  const Future = ({ bookingData }) => {
    // console.log(bookingData);
    const bookingConfirmData = bookingData?.bookingConfirms?.slice(0, 1)[0];
    const issueList = bookingConfirmData?.cardConfirmData;
    var issuesString = "";
    for (let item of issueList) {
      issuesString = issuesString + item?.issueName;
    }

    const date = new Date(bookingConfirmData?.bookingSlot);

    return (
      <Link
        to={`/bookings/details/${Encode(
          bookingConfirmData?.id?.toString() || "00"
        )}`}
        state={{ booking: bookingConfirmData }}
        className=" bookings-items hover:scale-[1.01] transition-all ease-in-out flex px-[1.5rem] relative items-center gap-2 py-2 cursor-pointer w-full ">
        <div className=" h-[3rem] w-[3rem] flex flex-col items-center justify-center mr-[1.5rem] ">
          <FaLocationDot className="w-full h-full" />
        </div>
        <div className="flex flex-col h-full w-1/4 justify-center">
          <span className="text-lg font-semibold">
            {issuesString.length > 13
              ? `${issuesString.slice(0, 13)}...`
              : issuesString}
          </span>
          <span className="text-xs font-semibold">
            {bookingConfirmData?.subCategoryName}
          </span>
          <span className="text-xs mt-2 text-center overflow-hidden inline-block whitespace-nowrap overflow-ellipsis w-full  ">
            {"307, K. P Square , Ground Floor, 100 Feet Rd, Binnamangala"}
          </span>
        </div>
        <div className="border-r h-5/6 border-black"></div>
        {/* time */}
        <div className=" font-medium flex flex-col gap-2 ml-9 w-1/4">
          <span>{date?.toDateString()}</span>
          <span>{date?.toLocaleTimeString()}</span>
        </div>

        <div className="absolute right-8 font-bold text-white py-4 w-[10%] text-center rounded-xl bg-primary-max ">
          <span>₹{bookingConfirmData?.amount}</span>
        </div>
      </Link>
    );
  };

  const Ongoing = ({ bookingData }) => {
    const bookingConfirmData = bookingData?.bookingConfirms?.slice(0, 1)[0];
    const issueList = bookingConfirmData?.cardConfirmData;
    var issuesString = "";
    for (let item of issueList) {
      issuesString = issuesString + item?.issueName;
    }

    const date = new Date(bookingConfirmData?.bookingSlot);
    return (
      <Link
        to={`/bookings/details/${Encode(
          bookingConfirmData?.id?.toString() || "00"
        )}`}
        state={{ booking: bookingConfirmData }}
        className=" w-full bookings-items hover:scale-[1.01] transition-all ease-in-out flex px-4 relative items-center gap-2 py-2 cursor-pointer ">
        <div className=" border h-full w-[14%] flex flex-col items-center justify-center rounded-xl border-gray-400">
          <BiSolidMap className="text-3xl" />
          <span className="text-sm"></span>
        </div>
        <div className="flex flex-col h-full">
          <span className="text-xl font-semibold">
            {issuesString.length > 13
              ? `${issuesString.slice(0, 13)}...`
              : issuesString}
          </span>
          <span className="text-md">{bookingConfirmData?.subCategoryName}</span>
        </div>
        {/* time */}
        <div className="w-full text-center flex flex-col absolute text-sm font-semibold">
          <span>{date?.toDateString()}</span>
          <span>{date?.toLocaleTimeString()}</span>
        </div>

        <div className="absolute right-2  font-semibold h-5/6 w-2/12 flex flex-col items-center justify-center rounded-xl">
          <div className="text-lg ">
            <span>₹{bookingConfirmData?.amount}</span>
          </div>
        </div>
      </Link>
    );
  };

  const Completed = ({ bookingData }) => {
    const bookingConfirmData = bookingData?.bookingConfirms?.slice(0, 1)[0];
    const issueList = bookingConfirmData?.cardConfirmData;
    var issuesString = "";
    for (let item of issueList) {
      issuesString = issuesString + item?.issueName;
    }

    const date = new Date(bookingConfirmData?.bookingSlot);
    return (
      <Link
        Link
        to={`/bookings/details/${Encode(
          bookingConfirmData?.id?.toString() || "00"
        )}`}
        state={{ booking: bookingConfirmData }}
        className=" bookings-items w-full hover:scale-[1.01] transition-all ease-in-out  shadow-m flex flex-col px-4 relative justify-center cursor-pointer">
        <span className="text-xl font-semibold">
          {" "}
          {issuesString.length > 13
            ? `${issuesString.slice(0, 13)}...`
            : issuesString}
        </span>
        <span className="text-md">{bookingConfirmData?.subCategoryName}</span>
        {/* <span className="text-sm">To:East Point Avalahalli</span> */}
        <span className="flex mt-1 hidden">
          <AiFillStar />
          <AiFillStar />
          <AiFillStar />
          <AiFillStar />
          <AiFillStar />
        </span>

        <div className="absolute right-2 text-2xl font-bold text-black py-5 w-[14%] text-center rounded-xl">
          <span>₹{bookingConfirmData?.amount}</span>
        </div>
      </Link>
    );
  };

  const Cancelled = ({ bookingData }) => {
    return (
      <div className="bookings-items hover:scale-105 transition-all ease-in-out shadow-m flex flex-col px-4 relative justify-center cursor-not-allowed">
        <span className="text-xl font-semibold">Laptop +1</span>
        <span className="text-md">Some Name</span>
        <div className="absolute right-2  border h-5/6 w-[14%] flex flex-col items-center justify-center rounded-xl border-gray-400 text-lg font-semibold bg-black text-white">
          <div className="text-3xl">
            <BiBlock />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="w-[95%] flex items-center pt-6 gap-3 flex-col mx-auto">
      {bookings?.map((elem, index) => {
        const status = elem?.bookingConfirms
          ?.slice(0, 1)[0]
          ?.status?.toLowerCase();
        switch (status) {
          case "future":
            return <Future bookingData={elem} />;
          // case "cancelled":
          //   return <Cancelled bookingData={elem} />;
          case "ongoing":
            return <Ongoing bookingData={elem} />;
          case "completed":
            return <Completed bookingData={elem} />;
        }
      })}
      {!bookings?.length && (
        <div className="bookings-items transition-all ease-in-out shadow-m flex flex-col px-4 relative justify-center cursor-not-allowed w-full text-center text-xl text-gray-600">
          No Bookings Found
        </div>
      )}
    </div>
  );
};

export default BookingCards;
