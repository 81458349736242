import React from "react";
import talency_logo from "../Images/talency_logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";

const SignUpSidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const stepPaths = {
    "/register": 1,
    "/register/individual": 1,
    "/register/verify-otp": 2,
    "/register/documents/aadhar": 3,
    "/register/documents/pan": 4,
    "/register/company": 4,
  };
  const [steps, setStep] = useState(
    stepPaths[location.pathname] ? stepPaths[location.pathname] : 6
  );
  console.log(location);
  const handleNavigate = (path) => {
    console.log(path);
    if (stepPaths[location.pathname] > stepPaths[path]) {
      navigate(path, { state: location.state });
    }
  };

  <div className="w-[1fr] bg-[#F0F0F0] border-r border-gray-300 pt-10 h-max min-h-screen ">
    <div>
      <img src={talency_logo} alt="Logo" className="mx-auto w-5/6" />

      {/* Step Cards */}
      <div className="m-auto w-5/6 mt-10">
        <div
          className={
            "w-full   bg-white  text-center p-5 font-semibold capitalize loadleft cursor-not-allowed hover:bg-[#ffffcc26] " +
            (steps > 1
              ? " border-[#4CAF50] text-[#4CAF50] "
              : " border-black") +
            (steps === 1 ? " border-2" : " border ")
          }>
          Register
        </div>
        <div className="h-10 border-r-2 w-min m-auto border-dashed border-gray-400"></div>
        <div
          className={
            "w-full   bg-white  text-center p-5 font-semibold capitalize loadleft cursor-not-allowed hover:bg-[#ffffcc26] " +
            (steps > 2
              ? " border-[#4CAF50] text-[#4CAF50] "
              : " border-black") +
            (steps === 2 ? " border-2" : " border ")
          }>
          verify OTP
        </div>
        <div className="h-10 border-r-2 w-min m-auto border-dashed border-gray-400"></div>
        <div
          className={
            "w-full   bg-white  text-center p-5 font-semibold capitalize loadleft cursor-pointer  hover:bg-[#ffffcc26] " +
            (steps > 3
              ? " border-[#4CAF50] text-[#4CAF50] "
              : " border-black") +
            (steps === 3 ? " border-2" : " border ")
          }
          onClick={() => handleNavigate("/register/partner-details")}>
          Skill Details
        </div>
        <div className="h-10 border-r-2 w-min m-auto border-dashed border-gray-400"></div>
        <div
          className={
            "w-full   bg-white  text-center p-5 font-semibold capitalize loadleft cursor-pointer hover:bg-[#ffffcc26] " +
            (steps > 4
              ? " border-[#4CAF50] text-[#4CAF50] "
              : " border-black") +
            (steps === 4 ? " border-2" : " border ")
          }
          onClick={() => handleNavigate("/register/documents/aadhar")}>
          Aadhar
        </div>
        <div className="h-10 border-r-2 w-min m-auto border-dashed border-gray-400"></div>
        <div
          className={
            "w-full   bg-white  text-center p-5 font-semibold capitalize loadleft cursor-pointer hover:bg-[#ffffcc26] " +
            (steps > 5
              ? " border-[#4CAF50] text-[#4CAF50] "
              : " border-black") +
            (steps === 5 ? " border-2" : " border ")
          }
          onClick={() => handleNavigate("/register/documents/pan")}>
          PAN
        </div>
        <div className="h-10 border-r-2 w-min m-auto border-dashed border-gray-400"></div>
        <div
          className={
            "w-full   bg-white  text-center p-5 font-semibold capitalize loadleft cursor-pointer hover:bg-[#ffffcc26] " +
            (steps > 5
              ? " border-[#4CAF50] text-[#4CAF50] "
              : " border-black") +
            (steps === 5 ? " border-2" : " border ")
          }>
          Review
        </div>
      </div>
    </div>
  </div>;

  return (
    <div
      className="w-full flex p-8 border-b font-mono text-gray-500 items-center justify-center gap-4 uppercase bg-white z-10 
    "
      style={{ boxShadow: "5px 4px 30px 0px rgb(0,0,0,0.1)" }}>
      <div
        className={
          "cursor-not-allowed " +
          (steps === 1
            ? " text-[#ff57b6] font-semibold loadleft"
            : steps > 1
            ? " text-[#4CAF50] "
            : " ")
        }>
        Register
      </div>

      <div className="w-[5%] border-dashed border-b-2 h-0"></div>
      <div
        className={
          "cursor-not-allowed " +
          (steps === 2
            ? " text-[#ff57b6] font-semibold loadleft"
            : steps > 2
            ? " text-[#4CAF50] "
            : " ")
        }>
        Verify OTP
      </div>
      <div className="w-[5%] border-dashed border-b-2 h-0"></div>
      <div
        onClick={() => handleNavigate("/register/documents/aadhar")}
        className={
          "cursor-pointer " +
          (steps === 3
            ? " text-[#ff57b6] font-semibold loadleft"
            : steps > 3
            ? " text-[#4CAF50] "
            : " ")
        }>
        Aadhar
      </div>
      <div className="w-[5%] border-dashed border-b-2 h-0"></div>
      <div
        onClick={() => handleNavigate("/register/documents/pan")}
        className={
          "cursor-pointer " +
          (steps === 4
            ? " text-[#ff57b6] font-semibold loadleft"
            : steps > 4
            ? " text-[#4CAF50] "
            : " ")
        }>
        PAN
      </div>
      <div className="w-[5%] border-dashed border-b-2 h-0"></div>
      <div
        className={
          "cursor-pointer " +
          (steps === 5
            ? " text-[#ff57b6] font-semibold loadleft"
            : steps > 5
            ? " text-[#4CAF50] "
            : " ")
        }>
        Review
      </div>
    </div>
  );
};

export default SignUpSidebar;
