import React, { useState, useEffect } from "react";
import { AiFillStar } from "react-icons/ai";
import { BiSolidMap, BiBlock } from "react-icons/bi";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { FaFilter } from "react-icons/fa";
import { BsSearch } from "react-icons/bs";
import "../../style/home.css";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import { fetchProfile } from "../../features/Profile/ProfileSlice";
import {
  getAllBookings,
  getCompanyBookings,
} from "../../features/Bookings/BookingSlice";
import BookingCards from "../../components/Home/BookingCards";
import { fetchCompany } from "../../features/Company/CompanySlice";
import BookingsList from "../../components/BookingsList";
import { getCompanyWalletTransactions } from "../../features/wallet/WalletSlice";
import WalletAmount from "../../components/WalletAmount";

const Home = () => {
  const dispatch = useDispatch();
  // States

  const [isBookingDetail, setBookingDetail] = useState(false);
  const [section, setSection] = useState("completed");
  const { userData } = useSelector((state) => state.profile);
  const { companyData } = useSelector((state) => state.company);
  const { CanceledBookings, CompletedBookings, FutureBookings } = useSelector(
    (state) => state.booking
  );

  // Functions
  const handleClick = () => {
    setBookingDetail((s) => !s);
  };
  const sectionSelect = (name) => {
    setSection(name?.toLowerCase());
  };

  useEffect(() => {
    dispatch(fetchProfile());
    dispatch(fetchCompany());
    dispatch(getCompanyBookings());
    dispatch(getCompanyWalletTransactions());
  }, []);
  return (
    <div className="min-h-screen flex py-10 bg-gray-50 justify-between min-w-max ">
      {/* Popups */}

      {/* Left */}
      <div className="w-[48%] flex flex-col items-center  gap-1">
        {/* partner Profile / */}
        <div className="w-11/12 shadow-lg  h-1/4 border-t bg-white flex relative ">
          <div className="w-1/4 text-center h-full flex items-center justify-center">
            <img
              src={userData?.profile_image_url || "/images/defaultUser.svg"}
              alt=""
              className="w-32 h-32 border rounded-full align-middle object-cover"
            />
          </div>
          <div className="w-3/4 h-full py-4 relative flex flex-col justify-center px-2 ">
            <div className="text-xl font-semibold">
              {companyData?.companyName}
            </div>
            <div className="text-sm ">
              {userData?.partnerFirstName + " " + userData?.partnerLastName}
            </div>

            <div className="text-xs">{companyData?.companyDomain}</div>

            <Link
              to="/profile"
              className="mt-5 w-full h-10 bg-primary-max text-xl rounded-md text-white uppercase flex items-center justify-center  ">
              View Profile
            </Link>
          </div>
          <div className="absolute flex items-center right-4 top-4 gap-1 bg-gray-200 px-1 rounded-md">
            <AiFillStar />
            4.5
          </div>

          {/* <div className="w-full"></div> */}
        </div>

        {/* wallet */}
        <section className="w-11/12 mx-auto mt-2">
          <WalletAmount />
        </section>
      </div>
      {/* Right */}
      <div className="w-[48%] flex flex-col gap-3">
        {/* Furure Bookings */}
        <div className="w-11/12 shadow-lg rounded-b-xl h-full border-t bg-white px-4 pt-2">
          <BookingsList />
        </div>
      </div>
    </div>
  );
};

export default Home;
