import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FiUser } from "react-icons/fi";
import { CiWallet } from "react-icons/ci";
import { IoSettingsOutline } from "react-icons/io5";
import { MdOutlineSupportAgent } from "react-icons/md";
import { DefaultUser } from "../Images";

const ProfileSidebar = () => {
  const { isLoading, userData } = useSelector((state) => state.profile);
  return (
    <div className="min-w-[16.875rem] heading-gradient  flex flex-col items-center justify-start max-md:hidden text-[#FFFFFFCC] pb-[10rem] ">
      <div>
        <img
          src={userData?.profile_image_url || "/images/defaultUser.svg"}
          alt="profile"
          className="mt-8 w-[10rem] h-[10rem] rounded-full bg-[#D9D9D9] m-auto"
        />
      </div>
      <div className="flex-col flex mt-[4rem] gap-[2.25rem] w-[60%]  text-lg  min-w-max ">
        <Link to={"/profile"} className="flex gap-6 px-2 items-center">
          <FiUser className="w-5 h-5" /> <span>Profile</span>
        </Link>
        <Link to={"/wallet"} className="flex gap-6 px-2 items-center">
          <CiWallet className="w-5 h-5" /> <span>Wallet</span>
        </Link>

        <Link to={"/"} className="flex gap-6 px-2 items-center">
          <MdOutlineSupportAgent className="w-5 h-5" /> <span>Help Center</span>
        </Link>
      </div>
    </div>
  );
};

export default ProfileSidebar;
