import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SlUserFemale, SlUser } from "react-icons/sl";
import { MdModeEdit } from "react-icons/md";
import { AiOutlineClose } from "react-icons/ai";
import ProfileSidebar from "../../components/ProfileSidebar";

const ProfileEdit = () => {
  const { isLoading, userData } = useSelector((state) => state.profile);
  const [editingPhone, setEditing] = useState(false);
  console.log(userData);

  const [firstName, setFirstName] = useState(userData?.partnerFirstName);
  const [lastName, setLastName] = useState(userData?.partnerLastName);
  const [email, setEmail] = useState(userData?.partnerEmail);
  const [gender, setGender] = useState(userData?.gender);
  const [location, setLocation] = useState(userData?.address?.city);
  const [altMobile, setAltMobile] = useState(userData?.altMobile);
  const [phone, setPhone] = useState(userData?.partnerMobile);
  const [age, setAge] = useState(userData?.age);

  const handelEdit = () => {
    setEditing((editingPhone) => !editingPhone);
  };
  const handelCancel = () => {
    setPhone(userData.phone);
    handelEdit();
  };
  const numberChange = (e) => {
    const { value } = e.target;
    setPhone(value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    switch (name) {
      case "name":
        setFirstName(value);
        break;
      case "location":
        setLocation(value);
        break;
      case "age":
        setAge(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "altMobile":
        setAltMobile(value);
        break;
    }
  };
  const genderChange = (id) => {
    const name = id;
    setGender(name === "male" ? "male" : "female");
  };

  return (
    <div>
      {editingPhone && (
        <div>
          <div className="fixed inset-0 bg-black z-10 opacity-50 "></div>
          <div className="fixed z-20 inset-0 flex items-center justify-center ">
            <div className="overflow-hidden opacity-100 z-40 w-1/4 h-2/5 rounded-md bg-gray-100">
              <div className="relative  flex items-center justify-center  bg-white w-full h-[15%]">
                <h4 className=" m-2 text-lg font-light">
                  Change Mobile Number
                </h4>
                <button
                  type="button"
                  onClick={handelEdit}
                  className="absolute right-0 text-xl p-1 m-1 mx-2 hover:bg-gray-200 ">
                  <AiOutlineClose />
                </button>
              </div>
              <div className=" h-[86%] w-full">
                <div className=" h-2/3 w-full flex justify-center items-center">
                  <div className=" relative border border-black w-5/6">
                    <span className="absolute -top-2 left-2 capitalize text-gray-400 text-xs p-0 mx-1 bg-white">
                      Mobile Number
                    </span>
                    <input
                      type="text"
                      name="phone"
                      className=" h-12 p-3 w-full "
                      placeholder="Mobile Number"
                      value={phone}
                      onChange={numberChange}
                    />
                  </div>
                </div>
                <div className=" flex justify-center items-center h-1/3 it gap-32 w-full ">
                  <button
                    className="border-2 px-4 p-1 h-8 border-black"
                    onClick={handelCancel}>
                    Cancel
                  </button>

                  {/* Yet to add mobile verification feature */}
                  <button
                    className="border-2 px-4 p-1 h-8 border-black bg-[#000] text-white"
                    onClick={() => setEditing(false)}>
                    Change
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className=" flex justify-center w-full h-full ">
        <div className="  w-[1200px] h-full my-20  ">
          {/* Head */}
          <div className="flex flex-col w-full border-b mx-4 pb-5 border-gray-400">
            <span className="text-xl font-bold my-1">Account</span>
            <span className="text-sm  font-thin">
              {userData?.firstName} {userData?.maidenName} {userData.lastName}
            </span>
          </div>

          {/* Profile box */}
          <div className="flex h-fit w-full ">
            <ProfileSidebar />

            {/* main box */}
            <div className="flex justify-center items-center w-full h-max ">
              <div className="flex justify-center min-w-max w-11/12 h-max mt-8 border border-gray-400">
                <div className="flex flex-col py-6 w-11/12 h-max text-gray-600">
                  <div className="h-[10%] w-full">
                    <div className="w-full border-b-2 p-4 text-lg font-bold">
                      Edit Details
                    </div>
                  </div>

                  <div className="flex flex-col gap-2 items-center  justify-center border-y h-[80%]">
                    <div className="relative flex items-center h-fit w-11/12 border mt-8 mb-4 border-black p-3">
                      <div>
                        <h4 className="text-xs text-gray-400 mx-3 font-thin">
                          Mobile Number *
                        </h4>
                        <h4 className="mx-3 text-sm font-normal">{phone}</h4>
                      </div>

                      {/* <button
                        type="button"
                        className="absolute flex justify-center items-center gap-2 right-0 m-4 w-2/6 rounded-sm  h-10 border border-black"
                        onClick={handelEdit}>
                        <MdModeEdit className="text-lg" />
                        <span>Change</span>
                      </button> */}
                    </div>

                    <div className="relative h-10 m-4  w-11/12 border border-black">
                      <span className="absolute -top-2 capitalize text-xs text-gray-400 px-1 left-5 bg-white">
                        full name
                      </span>
                      <input
                        type="text "
                        onChange={handleChange}
                        name="name"
                        value={firstName}
                        className="h-full p-3 w-full"
                      />
                    </div>
                    <div className="relative h-10 m-4  w-11/12 border border-black">
                      <span className="absolute -top-2 capitalize text-xs text-gray-400 px-1 left-5 bg-white">
                        email
                      </span>
                      <input
                        type="text "
                        name="email"
                        onChange={handleChange}
                        value={email}
                        placeholder="Email Address"
                        className="h-full p-3  w-full"
                      />
                    </div>
                    <div className="flex h-10 m-4  w-11/12 border border-black">
                      <button
                        type="button"
                        name="male"
                        onClick={() => genderChange("male")}
                        className={
                          gender === "male"
                            ? "w-1/2  flex gap-2 justify-center items-center border border-black bg-black text-white"
                            : "w-1/2  flex gap-2 justify-center items-center border border-black hover:bg-gray-100"
                        }>
                        <SlUser className="text-xl" />
                        <span>Male</span>
                      </button>
                      <button
                        name="female"
                        onClick={() => genderChange("female")}
                        className={
                          gender === "female"
                            ? "w-1/2  flex gap-2 justify-center items-center border border-black bg-black text-white"
                            : "w-1/2  flex gap-2 justify-center items-center border border-black hover:bg-gray-100"
                        }>
                        <SlUserFemale className="text-xl" />
                        <span>Female</span>
                      </button>
                    </div>
                    <div className="relative h-10 m-4  w-11/12 border border-black">
                      <span className="absolute -top-2 capitalize text-xs text-gray-400 px-1 left-5 bg-white">
                        Age
                      </span>
                      <input
                        type="text "
                        name="age"
                        onChange={handleChange}
                        className="h-full p-3  w-full"
                        value={age}
                        placeholder="Age"
                      />
                    </div>
                    <div className="relative h-10 m-4  w-11/12 border border-black">
                      <span className="absolute -top-2 capitalize text-xs text-gray-400 px-1 left-5 bg-white">
                        Location
                      </span>
                      <input
                        type="text "
                        name="location"
                        onChange={handleChange}
                        className="h-full p-3  w-full"
                        value={location}
                        placeholder="City,State"
                      />
                    </div>
                    <div className=" h-10 mx-4  w-11/12 text-lg font-semibold">
                      Alternate Mobile Number
                    </div>
                    <div className="relative h-10 m-4  w-11/12 border border-black">
                      <span className="absolute -top-2 capitalize text-xs text-gray-400 px-1 left-5 bg-white">
                        Mobile number
                      </span>
                      <input
                        type="text "
                        name="altMobile"
                        onChange={handleChange}
                        className="h-full p-3  w-full"
                        value={altMobile}
                        placeholder="Mobile Number"
                      />
                    </div>
                  </div>
                  <div className="h-[10%] w-full flex border-t justify-center">
                    <button className=" m-auto w-11/12 bg-primary-600 text-lg text-white my-4 p-1 rounded-lg ">
                      <span>Save Changes</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileEdit;
