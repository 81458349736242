import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { FaRegCopy } from "react-icons/fa";
import { Decode, handleCopy } from "../../utils/utilFunctions";
import {
  deleteBooking,
  deletePartner,
  getAllBookings,
  getBookingDetail,
  isCompleted,
  onGoingOTP,
} from "../../features/Bookings/BookingSlice";
import AssignPartner from "../../components/AssignPartner";
import { IoMdTime } from "react-icons/io";
import { toast } from "sonner";
import { fetchCompany } from "../../features/Company/CompanySlice";

const BookingDetail = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [showOtpPopup, setShowOtpPopup] = useState(false);
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();

  // Function to handle booking cancellation with OTP confirmation
  const handleBookingOngoing = () => {
    setShowOtpPopup(true);
  };

  const [isOngoing, setIsOngoing] = useState(false); // Track if OTP confirmation is ongoing

  const handleConfirmOtp = async (bookingId) => {
    if (otp) {
      try {
        const response = await dispatch(onGoingOTP({ bookingId, otp }));
        console.log(response);
        // Check if response has "ongoing" status
        if (booking?.status?.toUpperCase() === "ONGOING") {
          setIsOngoing(true); // Set ongoing state based on response data
        } else {
          setIsOngoing(false);
        }

        console.log("OTP Confirmed:", otp);
      } catch (error) {
        console.error("OTP Confirmation Failed:", error);
      } finally {
        setShowOtpPopup(false); // Close popup regardless of success or failure
        setOtp(""); // Clear OTP input after submission
        // Reset ongoing state only if not "ongoing" (optional)
      }
    }
  };

  const handleCompleted = async (bookingId, partnerId) => {
    toast.dismiss();
    toast(
      `Are you sure you want to Confirm Completion of ${booking?.partnerDto?.partnerFirstName} from this booking?`,
      {
        action: {
          label: "Confirm",
          onClick: async () => {
            const response = await dispatch(
              isCompleted({ bookingId, partnerId })
            );
          },
        },
      }
    );
  };

  // if (booking?.status?.toUpperCase() === "COMPLETED") {
  //   setIsOngoing(false);
  // }
  // else {
  //   setIsOngoing(true);
  // }

  const [isAssigningPartner, setAssigningPartner] = useState(null);

  const { BookingDetail } = useSelector((store) => store.booking);
  // console.log(BookingDetail)
  const [booking, setBooking] = useState({});

  const { companyData } = useSelector((store) => store.company);

  const handleStatusChange = (e) => {
    const value = e?.target?.value;
  };

  //Assign partner popup
  const handleAssigningPartner = (id) => {
    if (isAssigningPartner) {
      setAssigningPartner(null);
    } else setAssigningPartner(id);
  };

  const handleRemovePartner = () => {
    const bookingId = booking?.id;
    // const partnerId = booking?.partnerDto?.id;

    const companyId = companyData?.id;
    console.log(companyId);
    toast.dismiss();
    toast(
      `Are you sure you want to remove ${booking?.partnerDto?.partnerFirstName} from this booking?`,
      {
        action: {
          label: "Confirm",
          onClick: async () => {
            const response = await dispatch(
              deletePartner({ bookingId, companyId })
            );
            if (response.meta.requestStatus === "fulfilled") {
              toast.success("Partner removed from booking successfully");
            } else {
              toast.error("Failed to remove partner from booking");
            }
          },
        },
      }
    );
  };

  const BookingCancel = () => {
    const bookingId = location?.state?.booking?.id;
    const companyId = companyData?.id;

    toast.dismiss();
    toast(`Are you sure you want to cancel this booking?`, {
      action: {
        label: "Confirm",
        onClick: async () => {
          const response = await dispatch(
            deleteBooking({ bookingId, companyId })
          );

          if (response.meta.requestStatus === "fulfilled") {
            toast.success("Booking was successfully Cancelled");
            navigate("/", { replace: true });
          } else {
            toast.error("Failed to Cancel booking");
          }
        },
      },
    });
  };

  // TODO API not Working
  useEffect(() => {
    const bookingId = location?.state?.booking?.id;
    console.log(location?.state);
    dispatch(fetchCompany());
    dispatch(getBookingDetail(bookingId));
  }, []);

  useEffect(() => {
    if (BookingDetail?.length > 0)
      setBooking(BookingDetail?.slice(0, 1)[0]?.bookingConfirm);
  }, [BookingDetail]);
  useEffect(() => {
    console.log(booking?.status);
    if (booking?.status?.toUpperCase() === "ONGOING") {
      setIsOngoing(true);
    } else if (booking?.status?.toUpperCase() === "COMPLETED") {
      setIsOngoing(false);
    } else {
      setIsOngoing(true);
    }
  }, [booking?.status]);

  return (
    <div className="min-h-screen  p-10">
      <div className="w-full h-max pb-10  flex items-center  gap-2 flex-col  mb-10 rounded-t-[0.625rem] ">
        {/* Popups */}
        {isAssigningPartner && (
          <AssignPartner
            handlePartner={handleAssigningPartner}
            bookingId={isAssigningPartner}
          />
        )}

        {/* Heading */}
        <div className="relative mx-auto  tracking-wider py-4 rounded-t-xl font-bold uppercase text-white pl-8 pr-12 flex items-center justify-between w-full heading-gradient ">
          <span className="text-xl">Service Bookings</span>
          <span className="text-sm">Booking id - {booking?.orderId}</span>
          <div className="text-sm text-black py-2 px-20 bg-white rounded-b-xl CustomShadow absolute top-[100%] right-0 booking-bar-shadow">
            Slot - {booking?.bookingSlot?.slice(0, 16)?.replace("T", " ")}
          </div>
        </div>
        {/* User and status */}
        <div className="container mx-auto bg-secondary-min CustomShadow pt-4 pb-8 px-6 flex booking-bar-shadow ">
          <div className=" w-full h-full flex justify-between ">
            <div className="flex flex-col text-[#000000CC] gap-2  text-sm h-full">
              <div className="  mb-2 font-medium w-1/2 min-w-max  ">
                User Details
                <div className="bg-[#00000046] h-[1.5px]"></div>
              </div>
              <div>
                <strong>Name - </strong>
                {booking?.userDetailsDtos?.firstName +
                  " " +
                  (booking?.userDetailsDtos?.lastName || "")}
              </div>
              {booking?.status?.toLowerCase() !== "completed" && (
                <>
                  <div>
                    <strong>Mobile Number - </strong>
                    {booking?.userDetailsDtos?.mobile}
                  </div>
                  <div>
                    <strong>Email Address - </strong>
                    {booking?.userDetailsDtos?.email}
                  </div>
                </>
              )}
            </div>
            <div className="w-fit gap-2 flex flex-col justify-center min-h-full ">
              {booking?.status?.toLowerCase() !== "completed" && (
                <div className="flex gap-2 text-sm mb-2 justify-between w-full">
                  <div className="w-max font-medium">Address-</div>
                  <div>
                    {booking?.addresses?.address
                      ? booking?.addresses?.address +
                        ", " +
                        booking?.addresses?.city +
                        ", " +
                        booking?.addresses?.state +
                        ", " +
                        booking?.addresses?.pinCode
                      : "-----------Not Available-----------"}
                  </div>
                  {booking?.addresses?.address && (
                    <div
                      className="flex items-center justify-end cursor-pointer"
                      onClick={() => handleCopy(booking?.addresses?.address)}>
                      <FaRegCopy className="h-4" />
                    </div>
                  )}
                </div>
              )}
              {/* <div className="w-full text-white py-1 bg-primary-max text-center font-semibold text-sm rounded-md">
              Change Address
            </div> */}
            </div>
            <div className="flex gap-2 items-center pt-8 justify-end flex-col">
              <div className=" flex flex-row gap-2">
                <div>Booking Status-</div>
                <div
                  name=""
                  id=""
                  className=" text-sm px-8 py-1 rounded-md border-[0.5px] border-[#00000057]">
                  {booking?.status?.toUpperCase()}
                </div>
              </div>
              {booking?.status?.toLowerCase() === "future" &&
                booking?.partnerDto?.id > 0 && (
                  <div
                    onClick={handleBookingOngoing}
                    className="text-sm px-7 py-2 justify-end self-end rounded-md bg-gray-600 text-white font-bold cursor-pointer">
                    Ongoing
                  </div>
                )}

              {booking?.status?.toLowerCase() === "ongoing" && (
                <div
                  onClick={() =>
                    handleCompleted(booking?.id, booking?.partnerDto?.id)
                  }
                  className="text-sm px-7 py-2 justify-end self-end rounded-md bg-gray-600 text-white font-bold cursor-pointer">
                  Completed
                </div>
              )}

              {/* OTP Confirmation Popup */}
              {showOtpPopup && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
                  <div className="bg-white p-6 rounded-lg w-80">
                    <h2 className="text-lg font-semibold mb-4 text-center">
                      Enter OTP to Confirm
                    </h2>
                    <input
                      type="text"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      placeholder="Enter OTP"
                      className="w-full px-3 py-2 mb-4 border border-gray-300 rounded focus:outline-none"
                      inputMode="numeric" // Ensures the numeric keypad on mobile devices
                      maxLength="6" // Limits input length to 6 characters
                    />

                    <div className="flex justify-end gap-2">
                      <button
                        onClick={() => setShowOtpPopup(false)}
                        className="px-4 py-2 rounded-md bg-gray-300 text-gray-800 font-semibold">
                        Cancel
                      </button>
                      {/* <button
                onClick={() => handleConfirmOtp(booking?.id)}
                className={`px-4 py-2 rounded-md font-semibold ${
                  isOngoing ? "bg-green-500" : "bg-blue-600"
                } text-white`}
              >
                {isOngoing ? "Ongoing" : "Confirm"}
              </button> */}
                      <button
                        onClick={() => handleConfirmOtp(booking?.id)} // Wrap in an anonymous function
                        className="px-4 py-2 rounded-md bg-blue-600 text-white font-semibold">
                        Confirm
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {booking?.status?.toLowerCase() === "future" && (
                <div
                  onClick={() => BookingCancel()}
                  className=" text-sm px-9 py-2 justify-end self-end rounded-md  bg-red-600 text-white font-bold cursor-pointer">
                  Cancel
                </div>
              )}
            </div>
          </div>
        </div>
        {/* Partner detail */}
        <div className="container mx-auto bg-secondary-min CustomShadow pt-4 pb-8 px-6  mt-2 booking-bar-shadow ">
          <div className="  mb-2 font-medium w-1/6  ">
            Partner Details
            <div className="bg-[#00000046] h-[1.5px]"></div>
          </div>

          <div className="flex text-sm items-center justify-between">
            <div>
              <span className="font-medium">Name -</span>
              <span className="capitalize">
                {booking?.partnerDto?.partnerFirstName || "--"}
              </span>
              <br />
              <span className="font-medium">Mobile -</span>
              <span>{booking?.partnerDto?.partnerMobile || "--"}</span>
            </div>
            <div>
              <span className="font-bold">Partner ID -</span>
              <span className="font-bold">
                {booking?.partnerDto?.id || "--"}
              </span>
              <br />
              <span className="font-medium">Mobile -</span>
              <span>{booking?.partnerDto?.partnerEmail || "--"}</span>
            </div>
            {booking?.status?.toLowerCase() === "future" ? (
              booking?.partnerDto?.id ? (
                <div
                  className="cursor-pointer font-semibold px-8 py-3 bg-primary-max rounded-md text-white"
                  onClick={() => handleRemovePartner(booking?.id)}>
                  Remove Partner
                </div>
              ) : (
                <div
                  className="cursor-pointer font-semibold px-8 py-3 bg-primary-max rounded-md text-white"
                  onClick={() => handleAssigningPartner(booking?.id)}>
                  Assign Partner
                </div>
              )
            ) : (
              <div></div>
            )}
          </div>
        </div>
        {/* Items and slot */}
        <div className="flex w-full gap-2 mt-2">
          <div className="flex-1 min-h-full flex flex-col booking-bar-shadow">
            <div className="w-full py-3 px-7 bg-secondary-min CustomShadow flex-1 ">
              <div className="  mb-2 font-medium w-1/6  ">
                Booking Details
                <div className="bg-[#00000046] h-[1.5px]"></div>
              </div>
              <div className="flex items-center gap-4 mt-5 w-full">
                <div className="w-1/2 flex items-center">
                  <span className="font-bold text-sm">Category Name -</span>
                  <div className="px-2 py-1 text-sm  bg-white rounded-md border-[0.5px] border-[#00000057] ml-1 w-1/2 min-w-max">
                    {booking?.categoryName}
                  </div>
                </div>
                <div className="w-1/2 flex items-center">
                  <span className="font-bold text-sm">Sub-Category Name -</span>
                  <div className="px-2 py-1 text-sm  bg-white rounded-md border-[0.5px] border-[#00000057] ml-1 w-1/2 min-w-max">
                    {booking?.subCategoryName}
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full py-3 px-7 bg-secondary-min CustomShadow mt-2">
              <div className="  mb-2 font-medium w-1/6  ">
                Slot Selection
                <div className="bg-[#00000046] h-[1.5px]"></div>
              </div>
              <div className="flex items-center w-full ">
                <div className="w-1/2 flex items-center ">
                  <div className=" py-1 font-medium bg-white rounded-md  text-lg ml-1 w-1/2 min-w-max">
                    {booking?.bookingSlot?.slice(0, 16)?.replace("T", " ")}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*issues  */}
          <div className="w-5/12 py-3 px-7 bg-secondary-min CustomShadow booking-bar-shadow ">
            <div className="  mb-6 font-medium w-1/3  ">
              List of Issues
              <div className="bg-[#00000046] h-[1.5px]"></div>
            </div>
            {booking?.cardConfirmData?.map((elem, index) => {
              return (
                <div
                  key={index}
                  className="relative p-3 border-[0.5px] mt-1 border-[#00000099] bg-white rounded-md flex justify-between items-center ">
                  <span className="font-bold text-[#000000CC]">
                    {elem?.issueName}
                  </span>
                  <span className="text-[#00000099] font-bold text-sm absolute left-1/2">
                    {"₹ " + elem?.issuePrice}
                  </span>
                  <span className="text-[#00000099] font-bold text-sm flex gap-1 items-center">
                    <IoMdTime className="text-xl" />
                    {elem?.issueDuration + " mins"}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
        {/* Price details */}
        <div className="flex w-full gap-3 booking-bar-shadow">
          {/* Consumer */}

          {/* Partner */}
          <div className="container mx-auto bg-secondary-min CustomShadow pt-4 pb-8 px-6  mt-2 ">
            <div className="flex items-center justify-between relative">
              <div className="  font-medium  w-1/3 min-w-max  ">
                Price Break-Up
                <div className="bg-[#00000046] h-[1.5px]"></div>
              </div>

              <span className="">
                MODE:{" "}
                <button
                  className={
                    "p-2 uppercase tracking-wider font-semibold rounded-lg " +
                    (booking?.paymentMode?.toLowerCase() === "cash"
                      ? "bg-[#F15B40] text-white"
                      : "bg-[#44AF12] text-white")
                  }>
                  {booking?.paymentMode}
                </button>
              </span>
            </div>

            <div className=" flex flex-col w-11/12 mx-auto gap-5 text-sm mt-10">
              <div className="flex w-full items-center justify-between ">
                <span>Total Amount</span>
                <span className="font-bold text-primary-max">
                  {"₹ " +
                    (booking?.actualAmount +
                      (parseInt(booking?.couponDiscount) || 0))}
                </span>
              </div>
              <div className="flex w-full items-center justify-between ">
                <span>Tax</span>
                <span className=" text-[#F15B40]">
                  {"-₹ " +
                    (parseInt(booking?.tax) +
                      parseInt(booking?.bookingCommission) || "00")}
                </span>
              </div>
              <div className="flex w-full items-center justify-between ">
                <span>Commission</span>
                <span className=" text-[#F15B40]">
                  {"-₹ " + (booking?.partnerCommission || "00")}
                </span>
              </div>

              <div className="flex w-full items-center justify-between ">
                <span>User Discount</span>
                <span className="font-bold text-[#44AF12]">
                  +₹ {parseInt(booking?.couponDiscount) || 0}
                </span>
              </div>

              <div className="flex w-full items-center justify-between text-lg text-primary-max border-y px-1 border-[#00000033] py-3">
                <span className="font-medium">Payable Amount</span>
                <span className="font-bold">
                  {"₹ " + (booking?.amount - booking?.partnerCommission)}
                </span>
              </div>
              {/* {
                <div className="flex w-full items-center justify-between ">
                  <span className="text-primary-max">Amount Paid</span>
                  <span className="font-bold text-[#BB2A10]">₹ XXX</span>
                </div>
              }
              <div className="flex w-full items-center justify-between ">
                <span className="text-primary-max">Company Due</span>
                <span className="font-bold text-[#44AF12]">₹ XXX</span>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingDetail;
