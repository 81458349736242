import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import customFetchDummy from "../../utils/axios";
import { logoutUser } from "../user/UserSlice";

const initialState = {
  isLoading: false,
  albumId: "",
  id: 0,
  title: "",
  url: "",
  thumbnailUrl: "",
};

// Get Api
export const fetchHome = createAsyncThunk(
  "home/fetchHome",
  async (_, thunkAPI) => {
    try {
      const resp = await customFetchDummy.get(
        "/photos"
        //   , {
        //     headers: {
        //       authorization: `Bearer ${thunkAPI.getState().user.user.id_token}`,
        //     },
        //   }
      );

      console.log(resp);
      if (resp.status === 200) {
        return resp.data;
      }
    } catch (error) {
      //   if (error.response && error.response.status === 401) {
      //     thunkAPI.dispatch(logoutUser());
      //     return thunkAPI.rejectWithValue("Unauthorized! Logging out... ");
      //   }
      //   return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Post Api

const CompanySlice = createSlice({
  name: "home",
  homes: [],
  initialState,
  reducers: {
    handleChange: (state, { payload: { name, value } }) => {
      state[name] = value;
    },
  },

  extraReducers: {
    [fetchHome.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchHome.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.homes = payload;
    },
    [fetchHome.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
  },
});

export const { handleChange } = CompanySlice.actions;
export default CompanySlice.reducer;
