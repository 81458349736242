import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PartnerProfileDetail from "../../components/PartnerProfileDetail";
import BookingsList from "../../components/BookingsList";
import { getAllBookings } from "../../features/Bookings/BookingSlice";
import { DefaultUser } from "../../Images";

const MemberDetail = () => {
  const dispatch = useDispatch();

  const { orgPartners } = useSelector((store) => store.profile);

  const { partnerId } = useParams();

  const partnerData = orgPartners?.content?.find((elem) => elem?.id == partnerId);

  useEffect(() => {
    dispatch(getAllBookings(partnerId));
  }, []);
  return (
    <div className="flex gap-8 py-10 px-8">
      <div className="flex-1 ">
        <h1 className="text-2xl font-bold capitalize">
          {partnerData?.partnerFirstName + " " + partnerData?.partnerLastName}
        </h1>
        <div className="mt-[0.625rem] flex h-[13.45rem] gap-2 w-full">
          <div className="flex-1 rounded-xl bg-gray-200"></div>
          <div className="w-[11.25rem] ">
            <img
              src={DefaultUser}
              alt=""
              className="w-full h-full rounded-lg border object-cover"
            />
          </div>
        </div>
        <div className="mt-3">
          <BookingsList />
        </div>
      </div>
      <div className="w-[36.5rem] sticky top-5 h-full ">
        <PartnerProfileDetail partnerDetail={partnerData} />
      </div>
    </div>
  );
};

export default MemberDetail;
