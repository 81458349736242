import { cryptoSecretKey } from "./Constants";

export function formatDate(date) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const day = String(date.getDate()).padStart(2, "0");
  const month = months[date.getMonth()];
  const year = String(date.getFullYear()).substr(-2);
  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // Handle midnight
  const formattedDate = `${day} ${month} ${year}\n${hours}:${minutes} ${ampm}`;
  return formattedDate;
}

export function formatTransactionDate(dateStr) {
  const date = new Date(dateStr);

  // Get month in abbreviated form
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = months[date.getMonth()];

  // Get day and add ordinal suffix
  const day = date.getDate();
  const daySuffix = (day) => {
    if (day > 3 && day < 21) return "th";
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  // Get year
  const year = date.getFullYear();

  // Format time in HH:MM:SS
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  // Combine all parts into final format
  return `${month} ${day}${daySuffix(
    day
  )}, ${year} ${hours}:${minutes}:${seconds}`;
}

export const handleCopy = (text) => {
  // Create a textarea element to copy the text
  const textarea = document.createElement("textarea");
  textarea.value = text;
  document.body.appendChild(textarea);

  // Select the text in the textarea
  textarea.select();

  // Copy the selected text to the clipboard
  document.execCommand("copy");

  // Remove the textarea element
  document.body.removeChild(textarea);

  // Alert the user that the text has been copied
  alert("Text copied to clipboard!");
};

const CryptoJS = require("crypto-js");

// Function to encode (encrypt) a message
export const Encode = (message) => {
  const ciphertext = CryptoJS.AES.encrypt(message, cryptoSecretKey).toString();

  // Convert to URL-safe Base64
  const urlSafeCiphertext = ciphertext
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, ""); // Remove any trailing '=' characters

  return urlSafeCiphertext;
};

// Function to decode (decrypt) a message
export const Decode = (ciphertext) => {
  // Convert from URL-safe Base64 back to normal Base64
  const normalCiphertext = ciphertext.replace(/-/g, "+").replace(/_/g, "/");

  const bytes = CryptoJS.AES.decrypt(normalCiphertext, cryptoSecretKey);
  return bytes.toString(CryptoJS.enc.Utf8);
};
