import { configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import UserSlice from "./features/user/UserSlice";
import homeSlice from "./features/Home/homeSlice";
import ProfileSlice from "./features/Profile/ProfileSlice";
import BookingSlice from "./features/Bookings/BookingSlice";
import FilesSlice from "./features/Files/FilesSlice";
import CompanySlice from "./features/Company/CompanySlice";
import WalletSlice from "./features/wallet/WalletSlice";

const persitConfig = {
  key: "root",
  version: 1,
  storage,
};

const reducer = combineReducers({
  user: UserSlice,
  home: homeSlice,
  profile: ProfileSlice,
  booking: BookingSlice,
  file: FilesSlice,
  company: CompanySlice,
  wallet: WalletSlice,
});

const persistedReducer = persistReducer(persitConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
});
