import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { BsFillJournalBookmarkFill } from "react-icons/bs";
import { FaIndianRupeeSign } from "react-icons/fa6";
import { FcApproval, FcSynchronize, FcCancel } from "react-icons/fc";
import { RiRefreshLine } from "react-icons/ri";
import { ObjectLoader } from "./EmpluxLoaders/Loader";
import { formatDate, formatTransactionDate } from "../utils/utilFunctions";
import { getCompanyWalletTransactions } from "../features/wallet/WalletSlice";
import { Link } from "react-router-dom";

const WalletAmount = ({ isAllPassbook = false }) => {
  const dispatch = useDispatch();
  const { isLoadingWallet, companyTransactions } = useSelector(
    (state) => state.wallet
  );
  return (
    <div className="flex justify-center items-center w-full  h-full  ">
      <div className="flex justify-center flex-col w-full h-full  relative gap-[1vh] ">
        <div className="bg-primary-600 text-white text-lg font-medium w-full text-center py-1">
          Transaction Details
        </div>
        {/* Wallet card */}
        <div className="p-4 border flex flex-col items-center  shadow-lg ">
          <div className="flex flex-col gap-3 py-4">
            <span className="font-mono text-sm font-bold">
              YOUR WALLET BALANCE
            </span>
            <div className="flex items-center text-2xl font-bold justify-center font-mono">
              <FaIndianRupeeSign className="text-xl" />
              <span>
                {!isLoadingWallet
                  ? companyTransactions
                      ?.slice(0, 1)[0]
                      ?.availableBalance?.toFixed(2) || "00"
                  : "..."}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-center py-4 border-t">
            <div className="flex flex-col items-center border-r px-4 ">
              <h1 className="text-sm">For transactio details</h1>
              <span
                className="font-bold cursor-pointer hover:underline"
                onClick={() => dispatch(getCompanyWalletTransactions())}>
                Refresh
              </span>
            </div>
            <div className="flex flex-col items-center border-l px-4 ">
              <h1 className="text-sm">Get your profit</h1>
              <span className="font-bold">Withdraw</span>
            </div>
          </div>
        </div>
        {/* Passbook */}
        <Link
          to="/wallet"
          className="bg-primary-600 text-white text-lg font-medium w-full  py-1 px-[1vw] flex items-center justify-between mt-[1vw]">
          <span>Pass-book</span>
          <BsFillJournalBookmarkFill className="text-2xl" />
        </Link>
        {/* payments list */}
        <div className="flex flex-col gap-[0.6vh] ">
          {/* Withdraw card */}
          {!isLoadingWallet ? (
            (isAllPassbook
              ? companyTransactions
              : companyTransactions?.slice(0, 3)
            )?.map((item, index) => {
              if (item?.creditedAmount > 0) {
                return (
                  <div
                    className="border py-[2vh] pl-[1vw] pr-[1.5vw] flex items-center justify-between rounded-md  shadow-lg "
                    key={index}>
                    <div className="flex gap-[0.5vw]">
                      <img src="/images/creditIcon.svg" alt="" />

                      <div className="flex flex-col gap-[0.1vw]">
                        <span className="text-xs font-semibold text-gray-400">
                          Credited
                        </span>
                        <span className="text-sm font-mono font-semibold">
                          {formatTransactionDate(item?.transactionDateTime)}
                        </span>
                      </div>
                    </div>
                    <h1 className="text-xl text-green-500 font-semibold">
                      {"+₹" + item?.creditedAmount?.toFixed(2)}
                    </h1>
                  </div>
                );
              }
              return (
                <div
                  className="border py-[2vh] pl-[1vw] pr-[1.5vw] flex items-center justify-between rounded-md  shadow-lg "
                  key={index}>
                  <div className="flex gap-[0.5vw]">
                    <img src="/images/debitIcon.svg" alt="" />

                    <div className="flex flex-col gap-[0.1vw]">
                      <span className="text-xs font-semibold text-gray-400">
                        Debited
                      </span>
                      <span className="text-sm font-mono font-semibold">
                        {formatTransactionDate(item?.transactionDateTime)}
                      </span>
                    </div>
                  </div>
                  <h1 className="text-xl text-red-500 font-semibold">
                    {"-₹" + item?.deductedAmount?.toFixed(2)}
                  </h1>
                </div>
              );
            })
          ) : (
            <ObjectLoader />
          )}
          {/* Credit */}
          <div className="border py-[2vh] pl-[0.5vw] pr-[1.5vw] flex items-center justify-between rounded-md  hidden">
            <div className="flex gap-[0.5vw]">
              <div className="h-full">
                <FcApproval className="text-xl " />
              </div>
              <div className="flex flex-col gap-[0.1vw]">
                <span className="text-xs font-semibold text-gray-400">
                  Credited
                </span>
                <span className="text-sm font-mono font-semibold">
                  Dec 22nd 2023
                </span>
              </div>
            </div>
            <h1 className="text-xl text-green-500 font-semibold">-₹3600</h1>
          </div>
          {/* in progress */}
          <div className="border py-[2vh] pl-[0.5vw] pr-[1.5vw] flex items-center justify-between rounded-md  hidden">
            <div className="flex gap-[0.5vw]">
              <div className="min-h-full ">
                <RiRefreshLine className="text-xl text-[#db9c43]" />
              </div>
              <div className="flex flex-col gap-[0.1vw]">
                <span className="text-xs font-semibold text-gray-400">
                  In Progress
                </span>
                <span className="text-sm font-mono font-semibold">
                  Dec 22nd 2023
                </span>
              </div>
            </div>
            <h1 className="text-xl text-[#db9c43] font-semibold">
              <span className="animate-ping ">..</span>.₹3600
            </h1>
          </div>
          {/* cancelled */}
          <div className="border py-[2vh] pl-[0.5vw] pr-[1.5vw] flex items-center justify-between rounded-md hidden ">
            <div className="flex gap-[0.5vw]">
              <div className="h-full">
                <FcCancel className="text-xl " />
              </div>
              <div className="flex flex-col gap-[0.1vw]">
                <span className="text-xs font-semibold text-gray-400">
                  Cancelled
                </span>
                {/* <h1 className="text-xl text-red-500 font-semibold line-through">
                        ₹3600
                      </h1> */}
                <span className="text-sm font-mono font-semibold">
                  Dec 22nd 2023
                </span>
              </div>
            </div>

            <h1 className="text-xl text-red-500 font-semibold line-through">
              ₹3600
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WalletAmount;
