import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import SignUpSidebar from "../components/SignUpSidebar";
import { HiMiniCloudArrowUp } from "react-icons/hi2";
import { BsCloudCheckFill } from "react-icons/bs";
import { FaFileUpload } from "react-icons/fa";
import { clearFile, uploadFile } from "../features/Files/FilesSlice";
import { ImSpinner3 } from "react-icons/im";
import { postGST, shiftLogin } from "../features/user/UserSlice";
import { useNavigate, useParams } from "react-router-dom";

const PartnerDocumnetsGst = () => {
  const front = useRef("");
  const back = useRef("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { partnerEmail } = useParams();

  const { uploadUrl, isUploadLoading } = useSelector((store) => store.file);
  const { signupData } = useSelector((store) => store.user);

  const [frontImage, setFrontImage] = useState();
  const [backImage, setBackImage] = useState();
  const [isSubmitted, setSubmitted] = useState(false);

  const handleClick = (isFront) => {
    if (isFront) {
      front?.current?.click();
    } else {
      back?.current?.click();
    }
  };

  const handleInputChange = (e, isFront) => {
    const getImage = e.target.files[0];
    if (!getImage) {
      return;
    }
    if (isFront) {
      setUpload({ ...upload, gst_img_url: "" });
      setFrontImage({
        ...frontImage,
        file: getImage,
        filepreview: URL.createObjectURL(getImage),
      });
    } else {
      setUpload({ ...upload, gstBackImgUrl: "" });
      setBackImage({
        ...backImage,
        file: getImage,
        filepreview: URL.createObjectURL(getImage),
      });
    }
  };

  const [upload, setUpload] = useState({
    gstNo: null,
    gst_img_url: "",
  });
  const handleGSTNumberChange = (e) => {
    const value = e?.target?.value;
    const number = value?.replace(/\D/g, "");

    setUpload({ ...upload, gstNo: number });
  };

  const [isFrontSide, setFrontSide] = useState(true);

  const handleUpload = (isFront) => {
    const fileData = new FormData();
    if (!isUploadLoading) {
      setFrontSide(isFront);
      const uploadGSTImage = isFront ? frontImage : backImage;
      if (uploadGSTImage?.file) {
        console.log(uploadGSTImage);
        fileData?.append(
          "file",
          uploadGSTImage?.file,
          uploadGSTImage?.file?.name
        );
        console.log(fileData);
        if (isFrontSide) {
          return dispatch(uploadFile(fileData));
        } else {
          return dispatch(uploadFile(fileData));
        }
      } else {
        alert("File not selected!");
      }
    } else {
      alert("Uploading another file!");
    }
  };

  //Submit form
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!upload?.gstNo || upload?.gstNo?.toString()?.length < 12) {
      alert("Enter valid gst");
    } else if (!frontImage) {
      alert("Add image");
    } else if (!upload?.gst_img_url) {
      setSubmitted(true);
      await handleUpload(true);
    } else {
      await dispatch(postGST({ partnerId: signupData?.userId, data: upload }));
    }
  };

  useEffect(() => {
    if (uploadUrl) {
      if (isFrontSide) {
        setUpload({ ...upload, gst_img_url: uploadUrl });
        setFrontImage({ ...frontImage, filepreview: null });
      } else {
        setUpload({ ...upload, gstBackImgUrl: uploadUrl });
        setBackImage({ ...backImage, filepreview: null });
      }
      console.log(upload);
      dispatch(clearFile());
    }
  }, [uploadUrl]);

  useEffect(() => {
    if (isSubmitted) {
      if (upload?.gst_img_url && upload?.gst_img_url) {
        dispatch(postGST({ partnerId: signupData?.userId, data: upload }));
      }
    }
  }, [upload]);

  useEffect(() => {
    if (signupData) {
      if (!signupData?.userId) {
        navigate("/login");
      } else if (!signupData?.active) {
        navigate(`/register/verify-otp/${partnerEmail}`);
      } else if (signupData?.screen) {
        if (signupData?.screen === "4") {
          dispatch(shiftLogin());
        } else if (signupData?.screen !== "3") {
          navigate(`/register/documents/pan/${partnerEmail}`);
        }
      }
    }
  }, [signupData]);

  //After selecting view

  const AfterSelectingImage = ({ isFront }) => {
    const imageUrl = isFront ? upload?.gst_img_url : upload?.gstBackImgUrl;

    const selectedFile = isFront ? frontImage : backImage;
    const fileName = imageUrl.split("/");
    return (
      <div className="flex flex-row h-20 items-center w-full">
        <img
          src={imageUrl || selectedFile?.filepreview}
          alt=""
          className="h-20 w-20 mr-4 rounded-[100%] "
        />
        <div className="w-full">
          <input
            type="file"
            ref={isFront ? front : back}
            className="hidden"
            onChange={(e) => handleInputChange(e, isFront)}
          />
          {imageUrl ? (
            <h2 className={"font-semibold text-lg py-1"}>File Uploaded</h2>
          ) : (
            <h2 className={"font-semibold text-lg py-1"}>Upload File</h2>
          )}

          {/* <p className={"text-gray-500 text-sm"}>image_name</p> */}
          <p className={"text-gray-500 text-sm"}>
            {fileName[-1] || selectedFile?.file?.name}
          </p>

          <button
            type="button"
            className={"my-2 text-xs font-bold text-pink-900 cursor-pointer"}
            onClick={() => handleClick(isFront)}>
            Re-Select
          </button>
          {selectedFile?.filepreview && (
            <button
              type="button"
              className=" my-2 px-3 text-xs font-bold text-pink-900"
              onClick={() => handleUpload(isFront)}>
              Upload
            </button>
          )}
        </div>
        <div className="flex justify-center pr-4 items-center ">
          {imageUrl && !selectedFile?.filepreview ? (
            <BsCloudCheckFill
              className={"text-5xl cursor-pointer text-green-600"}
            />
          ) : isUploadLoading && isFront === isFrontSide ? (
            <ImSpinner3 className="animate-spin text-5xl text-[#ff57b6] " />
          ) : (
            <HiMiniCloudArrowUp
              className={"text-5xl cursor-pointer text-[#ff57b6]"}
              onClick={() => handleUpload(isFront)}
            />
          )}
        </div>
      </div>
    );
  };

  //Before selectig view

  const BeforeSelectingImage = ({ isFront }) => {
    return (
      <div className="flex justify-center items-center w-full">
        <div className="flex flex-col border h-[70%] rounded-lg gap-1 p-3 w-full">
          <div className="py-4 text-gray-500 text-center">
            PDF, JPEG, PNG, or JPG formats only
          </div>
          <input
            type="file"
            ref={isFront ? front : back}
            className="hidden"
            onChange={(e) => handleInputChange(e, isFront)}
          />
          <button
            type="button"
            className="border flex flex-row items-center justify-center h-[50px] text-lg  rounded-lg gap-2 bg-[#ff57b6] text-white"
            onClick={() => handleClick(isFront)}>
            <FaFileUpload />
            <span>Upload File</span>
          </button>
          {/* <BiSolidCloudUpload /> */}
        </div>
      </div>
    );
  };

  return (
    <div className="login-container flex flex-col items-center">
      <SignUpSidebar />
      {/* Data Input */}
      <div className=" w-full flex items-center justify-center mt-5 ">
        <div className="w-[40rem]  border max-[900px]:w-11/12  no-scrollbar rounded-lg shadow-lg xl:h-[90%] md:min-h-max md:min-w-max lg:min-h-max lg:min-w-max sm:min-h-max sm:min-w-max 2xl:min-h-max 2xl:min-w-max pb-10 ">
          <h1 className="mx-auto w-max mt-8 mb-5 capitalize text-3xl font-semibold ">
            Upload GST
          </h1>
          <form
            className="flex flex-col  h-[83%] w-[80%]  m-auto gap-4 mb-5"
            onSubmit={handleSubmit}>
            <div className="relative w-full border rounded-md p-1 mb-4">
              <span className="absolute -top-3 capitalize text-sm text-gray-600 px-1 left-5 bg-white">
                GST No.
              </span>
              <input
                type="text"
                className="h-full w-full px-2 py-3"
                placeholder="XXXX XXXX XXXX  "
                value={upload?.gstNo}
                onChange={handleGSTNumberChange}
                maxLength={12}
                minLength={12}
                required
              />
            </div>

            <div className="flex  capitalize text-gray-600 justify-center items-center ">
              Image
            </div>
            {/* After selected */}
            {frontImage?.file ? (
              <AfterSelectingImage isFront={true} />
            ) : (
              <BeforeSelectingImage isFront={true} />
            )}
            {frontImage?.file && (
              <button
                type="submit"
                className="mt-10 w-full py-1 text-lg  rounded-lg gap-2 bg-[#ff57b6] text-white">
                Continue
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default PartnerDocumnetsGst;
