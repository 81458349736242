import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  forgotPassword,
  loginUser,
  setPassword,
} from "../features/user/UserSlice";
import { useNavigate } from "react-router-dom";
import signin_banner from "../Images/signin_banner.jpg";
import talency_logo from "../Images/talency_logo.png";

const initialState = {
  password: "",
  confirmpassword: "",
};

const SetPassword = () => {
  const [isDisable, setDisable] = useState(false);
  const [values, setValues] = useState(initialState);
  const { user, isLoading } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const url = window.location.href;
  const urls = url.split("=");
  var key = "";
  try {
    key = urls[1];
  } catch (error) {}

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setValues({ ...values, [name]: value });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    const { password, confirmpassword } = values;
    if (!password || !confirmpassword) {
      alert("Please fill out all fields");
      return;
    }
    dispatch(setPassword({ password: password }));
  };

  const toggleMember = () => {
    setTimeout(() => {
      navigate("/login");
    }, 10);
  };

  useEffect(() => {
    if (user || key === "" || !key) {
      setTimeout(() => {
        navigate("/");
      }, 10);
    }
  }, [user, navigate]);
  useEffect(() => {
    if (values.password === values.confirmpassword) {
      setDisable(true);
    } else setDisable(false);
  }, [values]);

  return (
    <div className="login-container flex flex-col lg:flex-row h-screen">
      <div className="image-section relative w-full lg:w-1/2">
        <img
          src={signin_banner}
          alt="Login"
          className="object-cover h-full w-full"
        />
      </div>

      <div className="w-full lg:w-1/2">
        <div className="mt-10">
          <img src={talency_logo} alt="Logo" className="mx-auto" />
        </div>

        <div className="w-full p-6 mx-auto lg:max-w-xl mt-10">
          <div className="form-section">
            <form onSubmit={onSubmit}>
              <div className="mb-2 mt-5">
                {/* Password field */}
                <input
                  type="password"
                  name="password"
                  placeholder="New Password"
                  onChange={handleChange}
                  className="block w-full px-4 py-2 mt-2 text-neutral-800 bg-stone-100 border rounded-md focus:border-neutral-800 focus:ring-indigo-600 focus:outline-none focus:ring focus:ring-opacity-40"
                />
              </div>
              <div className="mb-2 mt-5">
                {/* ConfirmPassword field */}
                <input
                  type="password"
                  name="confirmpassword"
                  placeholder="Confirm Password"
                  onChange={handleChange}
                  className="block w-full px-4 py-2 mt-2 text-neutral-800 bg-stone-100 border rounded-md focus:border-neutral-800 focus:ring-indigo-600 focus:outline-none focus:ring focus:ring-opacity-40"
                />
              </div>

              <div className="mt-6">
                <button
                  disabled={isLoading || !isDisable}
                  className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-neutral-800 rounded-md hover:bg-neutral-600 focus:outline-none focus:bg-purple-600">
                  {isLoading ? "Loading..." : "Submit"}
                </button>
              </div>

              <p className="mt-8 text-xs font-light text-center text-gray-700">
                Remember Password?
                <button
                  type="button"
                  className="font-medium text-neutral-800 outline-white hover:underline ml-2"
                  onClick={toggleMember}>
                  <strong>Sign In</strong>
                </button>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetPassword;
