import React, { useState, useEffect } from "react";
import { FaFilter } from "react-icons/fa";
import { BsSearch } from "react-icons/bs";
import { AiFillStar } from "react-icons/ai";

import "../../style/home.css";
import "../../style/Bookings/Bookings.css";

import BookingCards from "../../components/Home/BookingCards";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyBookings } from "../../features/Bookings/BookingSlice";

const UpcomingBookings = () => {
  const dispatch = useDispatch();
  const [isBookingDetail, setBookingDetail] = useState(false);
  const { FutureBookings } = useSelector((store) => store.booking);

  const handleClick = () => {
    const s = isBookingDetail;
    setBookingDetail(!s);
  };
  useEffect(() => {
    dispatch(getCompanyBookings());
  }, []);
  return (
    <div className="w-[85.526vw] m-auto flex items-center  flex-col  min-h-screen">
      {/* Popups */}

      <h1 className="min-[1900px]:text-5xl xl:text-3xl text-xl font-bold uppercase mt-10">
        Upcoming Bookings
      </h1>
      {/* Search and Filter */}
      <div className="h-20 w-full   flex items-center justify-between  ">
        <span className=" border overflow-hidden rounded-lg flex  min-h-max h-10">
          <input
            type="search"
            placeholder="Search"
            className="p-2 outline-none"
          />
          <div className="bg-black text-white  h-full px-2 flex items-center">
            <BsSearch className="text-xl " />
          </div>
        </span>
        <span className="flex justify-center flex-col items-center py-1 px-2 rounded-md border shadow-md cursor-pointer bg-black text-white ">
          <FaFilter className="text-xl" />
          <span className="text-xs">Filter</span>
        </span>
      </div>
      {/* Bookings */}
      <div className="w-full h-max pb-10 overflow-auto flex items-center  gap-4 flex-col border mb-10 rounded-t-[0.625rem] booking-bar-shadow">
        <div className="w-full py-5 px-12 font-bold text-white  text-xl heading-gradient ">
          Bookings
        </div>
        {/* Cards */}
        <BookingCards bookings={FutureBookings} handleClick={handleClick} />
      </div>
    </div>
  );
};

export default UpcomingBookings;
