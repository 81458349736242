import React from "react";
import { Toaster, toast } from "sonner";

import { Outlet } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import { PageLoader } from "../../components/EmpluxLoaders/Loader";
import { useSelector } from "react-redux";

const SharedLayout = () => {
  const { isLoadingProfile } = useSelector((store) => store.profile);
  const { isBookingLoading } = useSelector((store) => store.booking);
  return (
    <div>
      <Toaster position="bottom-right" reverseOrder={false} richColors />
      {(isLoadingProfile || isBookingLoading) && <PageLoader />}
      <div>
        <div className="flex">
          <Sidebar />
          <div className="w-full">
            {/* <Navigation /> */}
            <Outlet />
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  );
};

export default SharedLayout;
