import React, { useEffect } from "react";
import { useState } from "react";
import { IoMdHome } from "react-icons/io";
import { FaWallet } from "react-icons/fa";
import { FaBookmark, FaUser } from "react-icons/fa";
import { FaPeopleGroup } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";
import { RxCross1 } from "react-icons/rx";
import { IoIosArrowForward } from "react-icons/io";
import { Transition } from "@headlessui/react";
import { LuPower } from "react-icons/lu";
import { logoutUser } from "../features/user/UserSlice";
import { useDispatch } from "react-redux";

const Sidebar = () => {
  const paths = {
    1: "/",
    2: "/wallet",
    3.1: "/bookings/completed",
    3.2: "/bookings/cancelled",
    3.3: "/bookings/upcoming",
    4: "/profile",
    5: "/accountmember",
  };
  const pathCodes = {
    "/": 1,
    "/wallet": 2,
    "/bookings/completed": 3.1,
    "/bookings/cancelled": 3.2,
    "/bookings/upcoming": 3.3,
    "/profile": 4,
    "/accountmember": 5,
  };
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [multiMenuOpen, setMenu] = useState(pathCodes[location.pathname]);
  console.log(multiMenuOpen);
  const [isBigSidebarOpen, setBigSidebar] = useState(false);
  const [isSmallSidebarOpen, setSmallSidebar] = useState(
    isBigSidebarOpen ? false : true
  );
  const handleMultiMenu = (id) => {
    if (!isBigSidebarOpen) {
      setTimeout(() => {
        setBigSidebar(true);
      }, 310);
    }
    if (id === multiMenuOpen && isBigSidebarOpen) {
      setMenu(null);
    } else setMenu(id);
  };
  const handleNavigate = (id) => {
    if (id?.toString()[0] !== multiMenuOpen?.toString()[0]) {
      setMenu(null);
    }
    navigate(paths[id]);
  };
  const handleSidebaropen = () => {
    setBigSidebar((state) => !state);
  };

  const handleLogout = () => {
    dispatch(logoutUser("Logged-out successfully!"));
    console.log("User data after logout:", localStorage.getItem("user")); // Checking if user data is removed
    setTimeout(() => {
      navigate("/login"); // Navigate to login page after logout
    }, 100); // Delay navigation slightly after the alert
  };

  return (
    <div className={" min-h-full min-w-[90px]"}>
      <Transition
        show={isBigSidebarOpen}
        enter="transition ease-in-out duration-300 transform"
        enterFrom="-translate-x-2/3"
        enterTo="translate-x-0"
        leave="transition ease-in-out duration-300 transform"
        leaveFrom="translate-x-0"
        leaveTo="-translate-x-full"
        className={"min-h-full fixed z-40 "}>
        {/* logo */}
        <div
          className={
            " flex-col     max-[1000px]:hidden   justify-between px-4  border-r text-primary-max bg-white  top-0 h-[99vh]  flex z-20"
          }
          onMouseLeave={handleSidebaropen}>
          <div className="flex flex-col gap-[8px] items-center min-w-[60px]">
            <div className="h-[64px] flex items-center justify-between w-[230px]  mt-4">
              <div to={"/"} className="flex w-full items-center  gap-2">
                <img
                  src="https://clapurban.blr1.digitaloceanspaces.com/1713083984695_EMPLUX-removebg-preview (1).png"
                  alt=""
                  className="w-14"
                />
                <h1 className=" p-1  text-2xl font-bold uppercase text-primary-600  ">
                  EMPLUX
                </h1>
              </div>
              {/* <input
              type="radio"
              className="h-4 w-4 cursor-pointer accent-black "
              checked={isSidebarOpen}
              onClick={handleSidebaropen}
            /> */}
              <button onClick={handleSidebaropen}>
                <RxCross1 className="h-4 w-4 " />
              </button>
            </div>
            <div
              to={"/"}
              className={
                "flex items-center  gap-[8px] cursor-pointer h-[44px] w-[230px] rounded-xl px-3  mt-4 hover:scale-105 transition-all ease-in-out  " +
                (location.pathname === paths[1] && "bg-[#edededc7]")
              }
              onClick={() => handleNavigate(1)}>
              <IoMdHome className="min-w-[30px] min-h-[30px] " />
              <span>Home</span>
            </div>
            <div
              to={"/wallet"}
              className={
                "flex items-center  gap-[8px] cursor-pointer h-[44px] w-[230px] rounded-xl px-3  hover:scale-105 transition-all ease-in-out   " +
                (location.pathname === paths[2] && "bg-[#edededc7]")
              }
              onClick={() => handleNavigate(2)}>
              <FaWallet className="min-w-[22px] min-h-[22px] " />
              <span>Wallet</span>
            </div>
            <div className="flex flex-col gap-2 ">
              <div
                to={"/"}
                className={
                  "flex items-center  gap-[8px] cursor-pointer h-[44px] w-[230px] rounded-xl px-3  hover:scale-105 transition-all ease-in-out   " +
                  (multiMenuOpen?.toString()[0] === "3" && "bg-[#ededed46]")
                }
                onClick={() => handleMultiMenu(3)}>
                <FaBookmark className="min-w-[22px] min-h-[22px] " />
                <span className="w-full flex justify-between items-center">
                  Bookings
                  <span className="text-2xl">
                    {multiMenuOpen?.toString()[0] === "3" ? "-" : "+"}{" "}
                  </span>
                </span>
              </div>
              <div
                className={
                  " flex-col text-sm gap-[0.5vh] font-medium pl-[36px] text-gray-400 " +
                  (multiMenuOpen?.toString()[0] === "3"
                    ? " flex loadright"
                    : " hidden")
                }>
                <span
                  className={
                    "hover:scale-105 transition-all ease-in-out  cursor-pointer  w-[150px] px-3 py-1 rounded-md " +
                    (location.pathname === paths[3.1] && " bg-[#edededc7] ")
                  }
                  onClick={() => handleNavigate(3.1)}>
                  Completed
                </span>
                <span
                  className={
                    "hover:scale-105 transition-all ease-in-out  cursor-pointer  w-[150px] px-3 py-1 rounded-md " +
                    (location.pathname === paths[3.2] && " bg-[#edededc7]")
                  }
                  onClick={() => handleNavigate(3.2)}>
                  Cancelled
                </span>
                <span
                  className={
                    "hover:scale-105 transition-all ease-in-out  cursor-pointer  w-[150px] px-3 py-1 rounded-md " +
                    (location.pathname === paths[3.3] && " bg-[#edededc7]")
                  }
                  onClick={() => handleNavigate(3.3)}>
                  Upcoming
                </span>
              </div>
            </div>
            <div
              to={"/profile"}
              className={
                "flex items-center  gap-[8px] cursor-pointer h-[44px] w-[230px] rounded-xl px-3  hover:scale-105 transition-all ease-in-out   " +
                (location.pathname === paths[4] && "bg-[#edededc7]")
              }
              onClick={() => handleNavigate(4)}>
              <FaUser className="min-w-[22px] min-h-[22px] " />
              <span>Profile</span>
            </div>
            <div
              to={"/accountmember"}
              className={
                "flex items-center  gap-[8px] cursor-pointer h-[44px] w-[230px] rounded-xl px-3  hover:scale-105 transition-all ease-in-out   " +
                (location.pathname === paths[5] && "bg-[#edededc7]")
              }
              onClick={() => handleNavigate(5)}>
              <FaPeopleGroup className="min-w-[22px] min-h-[22px] " />
              <span>Members</span>
            </div>
          </div>
          <div
            className="p-1 border border-[#ff0000] rounded-md cursor-pointer py-2 px-3 w-[230px]  mb-[5vh] hover:bg-[#ff00000a] flex gap-[8px] items-center"
            onClick={handleLogout}>
            <LuPower className="min-w-[24px] min-h-[24px] text-[#ff0000]  " />
            <span className="font-mono text-[#ff0000] font-semibold text-xl">
              LOGOUT
            </span>
          </div>
        </div>
      </Transition>

      {/* logo */}
      <div
        className={
          " flex-col   max-[1000px]:hidden w-max px-2 justify-between   border-r text-primary-max bg-white  top-0 h-[99vh] sticky flex z-10 " +
          (isBigSidebarOpen && " hidden")
        }
        onMouseEnter={handleSidebaropen}>
        <div className="flex flex-col gap-[8px]  min-w-[60px]">
          <div className="h-[64px] flex items-center justify-between  relative mt-4">
            <div to={"/"} className="flex w-full items-center justify-center ">
              <img
                src="https://clapurban.blr1.digitaloceanspaces.com/1713083984695_EMPLUX-removebg-preview (1).png"
                alt=""
                className="w-12"
              />
            </div>
            <button
              className="absolute -right-5  text-sm font-bold  "
              onClick={handleSidebaropen}>
              <IoIosArrowForward
                className="text-2xl text-[#000] "
                style={{ textShadow: "0px 4px #000" }}
              />
            </button>
          </div>
          <div
            to={"/"}
            className={
              "flex items-center  gap-[8px] cursor-pointer h-[44px]  rounded-xl px-3  mt-4 hover:scale-105 transition-all ease-in-out justify-center " +
              (location.pathname === paths[1] && "bg-[#edededc7]")
            }
            onClick={() => handleNavigate(1)}>
            <IoMdHome className="min-w-[30px] min-h-[30px] " />
          </div>
          <div
            to={"/wallet"}
            className={
              "flex items-center  gap-[8px] cursor-pointer h-[44px]  rounded-xl px-3  hover:scale-105 transition-all ease-in-out justify-center  " +
              (location.pathname === paths[2] && "bg-[#edededc7]")
            }
            onClick={() => handleNavigate(2)}>
            <FaWallet className="min-w-[22px] min-h-[22px] " />
          </div>
          <div
            to={"/"}
            className={
              "flex items-center  gap-[8px] cursor-pointer h-[44px]  rounded-xl px-3  hover:scale-105 transition-all ease-in-out justify-center  " +
              (multiMenuOpen?.toString()?.slice(0, 2) === "3." &&
                "bg-[#edededc7]")
            }
            onClick={() => handleMultiMenu(3)}>
            <FaBookmark className="min-w-[22px] min-h-[22px] " />
          </div>
          <div
            to={"/profile"}
            className={
              "flex items-center  gap-[8px] cursor-pointer h-[44px]  rounded-xl px-3  hover:scale-105 transition-all ease-in-out justify-center  " +
              (location.pathname === paths[4] && "bg-[#edededc7]")
            }
            onClick={() => handleNavigate(4)}>
            <FaUser className="min-w-[22px] min-h-[22px] " />
          </div>
          <div
            to={"/accountmember"}
            className={
              "flex items-center  gap-[8px] cursor-pointer h-[44px]  rounded-xl px-3  hover:scale-105 transition-all ease-in-out justify-center  " +
              (location.pathname === paths[5] && "bg-[#edededc7]")
            }
            onClick={() => handleNavigate(5)}>
            <FaPeopleGroup className="min-w-[22px] min-h-[22px] " />
          </div>
        </div>
        <div
          className="p-1 border border-[#ff0000] rounded-md cursor-pointer mb-[5vh] hover:bg-[#ff00000a] "
          onClick={handleLogout}>
          <LuPower className="min-w-[22px] min-h-[22px] text-[#ff0000]  m-auto" />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
