import axios from "axios";

const customFetch = axios.create({
  baseURL: "https://apipartner.emplux.com/",
});
export const AdminCustomFetch = axios.create({
  baseURL: "http://64.227.149.177:8080/",
});

export default customFetch;
