import { BrowserRouter } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import "./style/popups.css";
import "./style/sidebar.css";
import Login from "./pages/Login";
import Register from "./pages/Register";
import ForgetPassword from "./pages/ForgetPassword";
import Error from "./pages/Error";
import ProtectedRoute from "./pages/ProtectedRoute";
import { Home, Profile, ProfileEdit } from "./pages/dashboard";
import SharedLayout from "./pages/dashboard/SharedLayout";
import SetPassword from "./pages/SetPassword";
import GetOtp from "./pages/GetOtp";
import PartnerDetails from "./pages/PartnerDetails";
import PartnerDocumnetsAadhar from "./pages/PartnerDocumnetsAadhar";
import PartnerDocumnetsPan from "./pages/PartnerDocumnetsPan";
import Documents from "./pages/dashboard/Documents";
import CompletedBookings from "./pages/dashboard/CompletedBookings";
import CancelledBookings from "./pages/dashboard/CancelledBookings";

import Wallet from "./pages/dashboard/Wallet";
import Member from "./pages/dashboard/Member";
import RegisterIndividual from "./pages/RegisterIndividual";
import PartnerDocumnetsGst from "./pages/PartnerDocumnetsGst";
import PaartnerLanding from "./pages/PaartnerLanding";
import UpcomingBookings from "./pages/dashboard/UpcomingBookings";
import DeleteUser from "./pages/DeleteUser";
import AddCompany from "./pages/AddCompany";
import MemberDetail from "./pages/dashboard/MemberDetail";
import BookingDetail from "./pages/dashboard/BookingDetail";
import { EditPartner } from "./pages";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <SharedLayout />
               </ProtectedRoute>
            }
            >
            <Route index element={<Home />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/bookings/completed" element={<CompletedBookings />} />
            <Route path="/bookings/cancelled" element={<CancelledBookings />} />
            <Route path="/bookings/upcoming" element={<UpcomingBookings />} />
            <Route
              path="/bookings/details/:encryptedBookingId"
              element={<BookingDetail />}
            />
            <Route path="/wallet" element={<Wallet />} />
            <Route path="/accountmember" element={<Member />} />
            <Route
              path="/accountmember/details/:partnerId"
              element={<MemberDetail />}
            />
            <Route path="/profile/documents" element={<Documents />} />
            <Route path="/profile/edit" element={<ProfileEdit />} />
            <Route path="/edit-partner/:partnerId" element={<EditPartner />} />
          </Route>

          <Route path="/register" element={<Register />} />
          <Route path="/register/individual" element={<RegisterIndividual />} />
          <Route
            path="/register/partner-details"
            element={<PartnerDetails />}
          />
          <Route
            path="/register/documents/aadhar/:partnerEmail"
            element={<PartnerDocumnetsAadhar />}
          />
          <Route
            path="/register/company/:partnerEmail"
            element={<AddCompany />}
          />
          <Route
            path="/register/documents/pan/:partnerEmail"
            element={<PartnerDocumnetsPan />}
          />
          <Route
            path="/register/documents/gst/:partnerEmail"
            element={<PartnerDocumnetsGst />}
          />

          <Route
            path="/register/verify-otp/:partnerEmail"
            element={<GetOtp />}
          />
          <Route path="/landing" element={<PaartnerLanding />} />
          <Route path="/setPassword" element={<SetPassword />} />
          <Route path="/login" element={<Login />} />
          <Route path="/user/delete" element={<DeleteUser />} />
          <Route path="/forgotpassword" element={<ForgetPassword />} />
          <Route path="*" element={<Error />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
