import React, { useState } from "react";
import { FaPlus } from "react-icons/fa";

const PartnerProfileDetail = ({ partnerDetail }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [partnerData, setPartnerData] = useState({
    partnerFirstName: partnerDetail?.partnerFirstName || "",
    partnerLastName: partnerDetail?.partnerLastName || "",
    partnerMobile: partnerDetail?.partnerMobile || "",
    partnerEmail: partnerDetail?.partnerEmail || "",
    categoryType: partnerDetail?.categoryType || "",
    skillSet: partnerDetail?.skillSet || [],
  });

  const handleChange = (e) => {
    setPartnerData({
      ...partnerData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSkillChange = (index, value) => {
    const updatedSkills = [...partnerData.skillSet];
    updatedSkills[index] = value; // Update the specific skill
    setPartnerData({ ...partnerData, skillSet: updatedSkills });
  };

  const handleAddSkill = () => {
    if (partnerData.skillSet.length < 3) {
      setPartnerData({
        ...partnerData,
        skillSet: [...partnerData.skillSet, ""], // Add a new empty skill
      });
    }
  };

  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  const handleCancel = () => {
    setPartnerData({
      partnerFirstName: partnerDetail?.partnerFirstName,
      partnerLastName: partnerDetail?.partnerLastName,
      partnerMobile: partnerDetail?.partnerMobile,
      partnerEmail: partnerDetail?.partnerEmail,
      categoryType: partnerDetail?.categoryType,
      skillSet: partnerDetail?.skillSet,
    });
    setIsEditMode(false);
  };

  const handleSave = () => {
    console.log("Updated Partner Data: ", partnerData);
    // Add save functionality here
    setIsEditMode(false);
  };

  return (
    <div className="w-full px-20 py-8 stShadow flex flex-col gap-5">
      <h1 className="font-bold text-[#000000CC] pb-2 border-black border-b">
        Profile Details
      </h1>

      {/* Full Name */}
      <div className="w-full flex justify-between text-[#00000099]">
        <span>Full Name</span>
        {isEditMode ? (
          <input
            type="text"
            name="partnerFirstName"
            value={partnerData.partnerFirstName}
            placeholder="Enter your Full Name"
            onChange={handleChange}
            className="border px-2"
          />
        ) : (
          <span>
            {partnerDetail?.partnerFirstName + " " + partnerDetail?.partnerLastName}
          </span>
        )}
      </div>

      {/* Mobile Number */}
      <div className="w-full flex justify-between text-[#00000099]">
        <span>Mobile Number</span>
        {isEditMode ? (
          <input
            type="text"
            name="partnerMobile"
            value={partnerData.partnerMobile}
            onChange={handleChange}
            placeholder="Enter your Mobile Number"
            className="border px-2"
          />
        ) : (
          <span>{partnerDetail?.partnerMobile}</span>
        )}
      </div>

      {/* Email */}
      <div className="w-full flex justify-between text-[#00000099]">
        <span>Email</span>
        <span>{partnerDetail?.partnerEmail}</span>
      </div>

      <h1 className="font-bold text-[#000000CC] pb-2 border-black border-b">
        Skill Details
      </h1>

      {/* Category */}
      <div className="w-full flex justify-between text-[#00000099]">
        <span>Category</span>
        {isEditMode ? (
          <select
            name="categoryType"
            value={partnerData.categoryType}
            onChange={handleChange}
            className="border px-2"
          >
            <option value="" disabled>Select Department</option>
            <option value={"mechanical"}>Mechanical</option>
            <option value={"electrical"}>Electrical</option>
            <option value={"electronics"}>Electronics</option>
          </select>
        ) : (
          <span>{partnerDetail?.categoryType}</span>
        )}
      </div>

      {/* Skills */}
      <div className="w-full flex justify-between text-[#000] font-medium">
        <span>Skills</span>
      </div>

      {partnerData.skillSet.map((skill, index) => (
        <div className="w-full flex justify-between text-[#00000099]" key={index}>
          <span></span>
          {isEditMode ? (
            <div className="flex items-center w-full">
              <input
                type="text"
                value={skill}
                required
                placeholder={skill?.length < 3 ? "Skills e.g. TV repair, laptop repair" : ""}
                onChange={(e) => handleSkillChange(index, e.target.value)}
                className="w-full h-full text-[1vw] max-[1000px]:text-[1.5vw] px-[1vw] py-[0.5vw] rounded-md border"
              />
              {partnerData.skillSet.length < 3 && (
                <button
                  onClick={handleAddSkill}
                  className="ml-2 text-black px-3 py-1"
                >
                  <FaPlus />
                </button>
              )}
            </div>
          ) : (
            <span>{skill}</span>
          )}
        </div>
      ))}

      {/* Conditional render: Edit button or Save/Cancel buttons */}
      {isEditMode ? (
        <div className="flex gap-5">
          <div
            onClick={handleSave}
            className="uppercase cursor-pointer font-bold text-white rounded-lg w-full py-2 bg-primary-max text-center"
          >
            Save
          </div>
          <div
            onClick={handleCancel}
            className="uppercase cursor-pointer font-bold text-white rounded-lg w-full py-2 bg-red-600 text-center"
          >
            Cancel
          </div>
        </div>
      ) : (
        <div
          onClick={toggleEditMode}
          className="uppercase cursor-pointer font-bold text-white rounded-lg w-full py-2 bg-primary-max text-center"
        >
          Edit
        </div>
      )}
    </div>
  );
};

export default PartnerProfileDetail;
